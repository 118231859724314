import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["disabled", "tabindex"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["disabled", "tabindex"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.dense)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args))),
        class: _normalizeClass(["ar-button-dense", {'warning-button': _ctx.warning, 'with-icon': _ctx.icon? true : false} ]),
        tabindex: _ctx.tabindex
      }, [
        (_ctx.icon && _ctx.iconBeforeText)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              innerHTML: _ctx.icon
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.text), 1),
        (_ctx.icon && !_ctx.iconBeforeText)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              innerHTML: _ctx.icon
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : (_ctx.inline)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args))),
          class: _normalizeClass(["ar-button-inline", {'disabled': _ctx.waitForResponse || _ctx.disabled, 'btn-raised': _ctx.raised}]),
          disabled: _ctx.waitForResponse || _ctx.disabled,
          tabindex: _ctx.tabindex
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.text), 1),
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                innerHTML: _ctx.icon
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ], 10, _hoisted_4))
      : (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args))),
          class: _normalizeClass(["ar-button with-loader", {'btn-raised':_ctx.raised, 'btn-secondary':_ctx.secondary, 'btn-primary': !_ctx.secondary, 'disabled': _ctx.waitForResponse || _ctx.disabled}]),
          disabled: _ctx.waitForResponse || _ctx.disabled,
          tabindex: _ctx.tabindex
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.text), 1),
          (_ctx.waitForResponse)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "svg-icon",
                innerHTML: _ctx.loader
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ], 10, _hoisted_6))
}