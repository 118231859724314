
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Button', 
    props:{
        text:{
            type: String, 
            default: 'Button'
        },
        waitForResponse:{
            type: Boolean, 
            default: false
        },
        dense:{
            type: Boolean, 
            default: false
        },
        warning:{
            type: Boolean, 
            default: false
        },
        secondary:{
            type: Boolean, 
            default: false
        },
        raised:{
            type: Boolean, 
            default: false
        },
        disabled:{
            type: Boolean, 
            default: false
        },
        inline: {
            type: Boolean, 
            default: false
        },
        icon:{
            type: String, 
            default: ''
        },
        iconBeforeText: {
            type: Boolean, 
            default: false
        },
        tabindex: {
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            loader: "<?xml version=\"1.0\" encoding=\"utf-8\"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version=\"1.1\" id=\"Ebene_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" viewBox=\"0 0 38 38\" style=\"enable-background:new 0 0 38 38;\" xml:space=\"preserve\"><style type=\"text/css\">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform=\"translate(1 1)\"><circle class=\"st0\" cx=\"18\" cy=\"18\" r=\"18\"/><path class=\"st1\" d=\"M36,18c0-9.9-8.1-18-18-18\"><animateTransform  accumulate=\"none\" additive=\"replace\" attributeName=\"transform\" calcMode=\"linear\" dur=\"1s\" fill=\"remove\" from=\"0 18 18\" repeatCount=\"indefinite\" restart=\"always\" to=\"360 18 18\" type=\"rotate\"></animateTransform></path></g></g></svg>",
        }
    },
    methods:{
        handleClicked(e:Event){
            e.preventDefault()
            this.$emit('handleClicked')
        }
    }
})
