
import { defineComponent } from 'vue';
import store from '@/store/index';

export default defineComponent({
    name: "Dialog",
    data(){
        return {
            store,
            dontShowAgain: false,
            icon:{
                close: '<svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="1.22457" y1="31.8427" x2="31.9152" y2="1.15215" stroke="#163D64" stroke-width="3"/><line x1="1.93168" y1="1.15231" x2="32.6223" y2="31.8429" stroke="#163D64" stroke-width="3"/></svg>',
            }
        }
    },
    props: {
        show:{
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        buttonText: {
            type: String,
            default:""
        },
        enableDontShowAgain:{
            type: Boolean,
            default: false
        },
        isModal:{
            type: Boolean,
            default: false
        },
        hideCancelButton:{
            type: Boolean,
            default: false
        },
    },
    computed:{
        getDisplayClass(){
           if(this.show){
            return 'showAlert'
           } else {
            return ''
           }
        },
    },
    watch: {
        dontShowAgain(){
            console.log(this.dontShowAgain)
            this.$emit('handleDontShowAgain', this.dontShowAgain)
        },
        show(){
            if(this.show){
                document.body.style.overflow = 'hidden';
                store.commit('setHideNavbar', true)

            } else {
                document.body.style.overflow = 'scroll'
                store.commit('setHideNavbar', false)
            }
        }
    },
    methods:{
        handleConfirm(){
            this.$emit('handleConfirm')
        },
        handleCancel(){
            this.$emit('handleCancel')
        }
    }
})
