
import {defineComponent} from 'vue';
import { mapState } from 'vuex';
import store from '../../store/index';

export default defineComponent({
  name: "NavBottom",
  props:{
    activeView: {
      type: String, 
      default: ""
    },
  },
  data(){
    return {
      store,
      startActive: true,
      meditationsActive: false,
      favoritsActive: false,
      accountActive: false,
      icons:{
        logo: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
        meditations: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 474.5" enable-background="new 0 0 500 474.5" xml:space="preserve"><g><path fill="#163D64" stroke="#163D64" stroke-width="8" stroke-miterlimit="10" d="M26.5,203.2c0-98.1,86.7-177.9,193.3-177.9c37.3,0,73.4,9.7,104.5,28.2c4.6,2.7,10.4,1,12.9-3.3c1.2-2.1,1.7-4.8,1-7.1c-0.6-2.5-2.1-4.6-4.4-5.8C300,17,260.6,6.4,219.8,6.4C102.8,6.4,7.7,94.8,7.7,203.2c0,53.9,23,104.3,64.9,141.8l0.2,0.2c1.7,1.5,3.9,2.1,6,2.1c2.7,0,5.4-1.2,7.1-3.3c1.7-1.9,2.5-4.6,2.5-7.1c-0.2-2.5-1.5-4.8-3.1-6.2C47.3,297.1,26.5,251.7,26.5,203.2z"/><path fill="#163D64" stroke="#163D64" stroke-width="8" stroke-miterlimit="10" d="M295.4,77.9c-108.6,0-196.9,82-196.9,182.7c0,57.2,29.5,111.8,78.9,146.3v52.4c0,3.7,2.1,6.9,5.6,8.3h0.2c0.8,0.2,1.9,0.6,3.1,0.6c2.5,0,4.6-0.8,6.2-2.5l33.9-33.9c21.9,7.7,45.2,11.5,69.1,11.5c108.6,0,196.9-82,196.9-182.7C492.3,159.9,404,77.9,295.4,77.9z M191.1,395c-47-31-75.1-81.2-75.1-134.2c0-91,80.5-165.2,179.4-165.2c98.7,0,179.2,73.9,179.2,165s-80.5,165.2-179.4,165.2c-23.7,0-46.6-4.2-68.3-12.5c-3.3-1.2-6.7-0.2-9,2.1L195,438.3v-36C195,399.4,193.6,396.7,191.1,395z"/><path fill="#163D64" stroke="#163D64" stroke-width="8" stroke-miterlimit="10" d="M260.2,326.6c3.7,2.1,7.9,3.3,12.1,3.3s8.5-1.2,12.3-3.5l77.4-44.7c7.5-4.4,12.1-12.3,12.1-21c0-8.7-4.6-16.7-12.1-21L284.6,195c-7.7-4.2-16.9-4.2-24.4,0c-7.5,4.4-12.1,12.3-12.1,21v89.5C248.1,314.3,252.7,322.4,260.2,326.6z M265.4,305.4v-89.5c0-2.3,1.3-4.6,3.5-6c1.3-0.8,2.3-1,3.5-1s2.1,0.4,3.3,1.2l77.6,44.9c2.1,1.2,3.5,3.3,3.5,6c0,2.3-1.3,4.6-3.5,6l-77.4,44.7c-2.3,1.3-4.8,1.3-7.1,0C266.6,310.1,265.4,307.9,265.4,305.4z"/></g></svg>',
        heart_outline: '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><g id="heart-svgrepo-com" transform="translate(0 -28.501)"><path id="Pfad_45" fill="none" stroke="#183E64" stroke-width="1.5" stroke-miterlimit="10" d="M21.1,32.8c-2.3-2.3-6.1-2.3-8.5,0L12,33.4l-0.6-0.6c-2.3-2.3-6.1-2.4-8.5,0c0,0,0,0,0,0c-2.3,2.3-2.3,6.1,0,8.5c0,0,0,0,0,0l8.6,8.6c0.2,0.2,0.6,0.2,0.9,0l0,0l8.6-8.6C23.4,38.9,23.4,35.1,21.1,32.8L21.1,32.8z"/></g></svg>',
        account: '<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 497 540.8" enable-background="new 0 0 497 540.8" xml:space="preserve"><g><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c31.4-30.1,50.9-72.3,50.9-119.2C414,99.2,340,25.1,248.6,25.1S83.2,99.2,83.2,190.5c0,46.9,19.5,89.1,50.9,119.2"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c-29.9,28.6-70.1,46.4-114.8,46.4s-84.9-17.8-114.6-46.4"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M133.8,309.8C73.8,355.4,31.6,435.7,24,529.5"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c60,45.7,102.2,125.9,109.9,219.7"/></g></svg>'
      }
    }
  },
  computed:{
    ...mapState({
      currentPage: 'currentPage'
    })
  },
  methods:{
    checkActivView(view:string){
      if(this.activeView.includes(view)){
        return true
      }
      return false
    },
    handleItemClick(event: Event, route:string, number:Number){
      event.preventDefault()
      this.$router.push(route)
      store.commit('goToPage', number)
    },
  }
})
