import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider-nav-box" }
const _hoisted_2 = { class: "slider-nav-content" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = (event) => _ctx.setFilteredCategory(event, -1)),
          class: _normalizeClass([_ctx.activeCategory == -1 ? 'isSelected': '', "slider-nav-item transparent-button"])
        }, _toDisplayString(_ctx.$t('duration.all')), 3)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.uid
        }, [
          _createElementVNode("button", {
            onClick: (event) =>_ctx.setFilteredCategory(event, item.uid),
            class: _normalizeClass([{'isSelected': _ctx.activeCategory==item.uid}, "slider-nav-item transparent-button"])
          }, _toDisplayString(item.title), 11, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}