
import { InfoRepository } from "../repository";

const InfoController = {
    faqs: [],

    async getFAQs() {
        const res : any = await InfoRepository.getFAQs();
        if(res.data.success){
            this.faqs = res.data?.data;
        } 
        return res;
    }
}
export default InfoController;