
import { InvoiceRepository } from "../repository";

const InvoiceController = {
    pdfSource: '',
    pdfFileName: '',
    pdfData: '',
    
    /**
     * downloadInvoice
     * 
     * @param {Object} invoice
     * @param {Object} pdfWindow
     * 
     * @returns {Object} server response
     */  
    async downloadInvoice(invoice: any/*, pdfWindow: any*/) {
        const postData : any = `tx_wwalprelax_invoice[invoiceId]=${invoice.uid}`
        const res : any = await InvoiceRepository.downloadInvoice(postData)
        if(res){
            console.log('InvoiceController:downloadInvoice', res)
            if(res.data?.success){
                if (res.data.pdf) {
                        //TODO this works with pdfWindow created directly in click event
                        //https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
                    //return res.data.pdf
                    /*const number = (invoice.invoiceNumber < 10 ? '0' : '') + invoice.invoiceNumber
                    const fileName = `alprelax-rechnung-${number}-${invoice.year}.pdf`
                    pdfWindow.document.write("<html<head><title>"+fileName+"</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
                    pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data.pdf)+"#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>");
                    */
                        this.pdfFileName = `alprelax-rechnung-${invoice.invoiceNumber}.pdf`
                        this.pdfSource = `data:application/pdf;base64,${res.data.pdf}`
                        this.pdfData = res.data.pdf
                        //pdfwindow.location = pdfSource

                        //pdfWindow.document.write("<html<head><title>"+fileName+"</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
                        //pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data.pdf)+"#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>");

                        //window.open(linkSource)
                        //const downloadLink = document.createElement("a")
                        //downloadLink.href = linkSource
                        //downloadLink.target = '_blank'
                        //const number = (invoice.invoiceNumber < 10 ? '0' : '') + invoice.invoiceNumber
                        //downloadLink.download = fileName
                        //downloadLink.click()
                }
                return res
            } 
            return res;
        }
    },

}
export default InvoiceController;