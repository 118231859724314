
import { defineComponent } from 'vue';

export default defineComponent({
    name: "CategorySlider",
    props: {
        categories: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            activeCategory: -1,
        }
    },
    methods: {
        setFilteredCategory(event: Event, value:number){
            event.preventDefault();
            this.activeCategory = value;
            this.$emit('handleSetCategoryFilter', this.activeCategory)
        },
    }
})
