
import {defineComponent} from 'vue';
import { NavBottom, PageHeader, FixedHeaderTop } from '@/components/';
import { UserController } from '@/controller';
import store from '@/store/index';
import { ResizeService } from '@/utils'
import { useEventBus } from '@vueuse/core'
import { alprelaxEventKey } from '@/bus/eventKey'

export default defineComponent({
  name: "Layout",
  components: { NavBottom, PageHeader, FixedHeaderTop },
  data() {
    return {
      store,
      user: null as any,
      username: '' as string,
      eventBus: useEventBus(alprelaxEventKey),
      appmainHeight: 0,
      goodDay: 'header.welcome',
      pageHeaderContent: ''
    };
  },
  computed: {
    viewClass() {
      let str = '' as String;
      const routename = this.$route.name as String;
      str += 'view-' + routename
      str += this.$route.meta.showFixedHeader ? ' has-fixed-header' : '' 
      str += this.$route.meta.showFooter ? ' has-footer' : ''
      return str;
    },
    key() {
      return this.$route.fullPath;
    },
    routeName() {
      return ( this.$route.name ? this.$route.name as string : '')
    },     
    checkIfNavBottom(){
      /* if(this.key.includes('/home') || this.key.includes('/account')|| this.key.includes('/meditations') || this.key.includes('/favorits') || this.key.includes('/edituser') || this.key.includes('/changepassword') || this.key.includes('/overviewabo') || this.key.includes('/manageabo')){
        return true
      } */
      return false
    },
    showFooter() {
      return this.$route.meta.showFooter
    },
    /* checkIfPageHeader(){
      if(this.key.includes('/home')|| this.key.includes('/meditations') || this.key.includes('/favorits')){
        return true
      }
      return false
    }, */
    showPageHeader() {
      return this.$route.meta.showPageHeader
    },
    showFixedHeader() {
      return this.$route.meta.showFixedHeader
    },
    getfPageHeaderContent(){
      const username : string = this.username
      const goodDay : string = this.goodDay
      
      if(this.key.includes('/home')){
        return this.$t(goodDay) + '<br>' + '<span class="bold">' + (username ? username : this.$t('alprelax')) + '</span>'
      } else if (this.key.includes('/meditations')){
        return '<span class="bold">' + this.$t('nav.meditations') + '</span>'
      } else if (this.key.includes('/favorits')){
        return '<span class="bold">' + this.$t('header.favorites') + '</span>'
      } else if (this.key.includes('/about')){
        return '<span class="bold">' + this.$t('header.about') + '</span>'
      }
      return ""
    }
  },
  watch: {
    showFooter: function(newValue, oldValue) {
        //use next tick to let dom be updated
      if (newValue != oldValue) this.$nextTick( () => { this.doResize() });
    },
    showFixedHeader: function(newValue, oldValue) {
        //use next tick to let dom be updated
      if (newValue != oldValue) this.$nextTick( () => { this.doResize() });
    }
  },
  created(){
    const today = new Date()
    const cHour = today.getHours()
    if (cHour < 12) {
      this.goodDay = 'header.good_morning'
    } else if (cHour < 18) {
      this.goodDay = 'header.good_afternoon'
    } else {
      this.goodDay = 'header.good_evening'
    }   

    this.user = UserController.user
    this.username  = (this.user ? this.user.firstName : '')
    
    this.appmainHeight = window.innerHeight - this.getHeaderFooterHeight()
    this.eventBus.on((e) => {
      if (e === 'onResize') this.doResize()
      if (e === 'onUserFetched') this.setUser();
    });     

  },
  mounted() {
    /* 
    console.log('crated this.username', this.username);
    if(this.key.includes('/home')){
        this.pageHeaderContent = this.$t(this.goodDay) + '<br>' + '<span class="bold">' + (this.username ? this.username : this.$t('alprelax')) + '</span>'
    } else if (this.key.includes('/meditations')){
       this.pageHeaderContent = '<span class="bold">' + this.$t('nav.meditations') + '</span>'
    } else if (this.key.includes('/favorits')){
        this.pageHeaderContent = '<span class="bold">' + this.$t('header.favorites') + '</span>'
    } */
  },
  methods: {
    doResize() {
      this.appmainHeight = ResizeService.height - this.getHeaderFooterHeight()
    },
    getHeaderFooterHeight():number {
      if (this.$refs) {
        let headerHeight = 0 as number
        let footerHeight = 0 as number
        const header = this.$refs.domFixedHeader as HTMLElement
        const footer = this.$refs.domFixedFooter as HTMLElement
        if (header) headerHeight = header.getBoundingClientRect().height
        if (footer) footerHeight = footer.getBoundingClientRect().height
        return headerHeight + footerHeight
      } else {
        return 0
      }
    },
    setUser() {
      this.user = UserController.user
      this.username = this.user ? this.user.firstName : ''
    },
    
  },  
})
