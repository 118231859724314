import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "duration-setter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('duration.title')), 1),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = (event) => _ctx.setFilteredDuration(event, 0)),
      class: _normalizeClass(["transparent-button", {'bold': _ctx.all}])
    }, _toDisplayString(_ctx.$t('duration.all')), 3),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = (event) => _ctx.setFilteredDuration(event, 1)),
      class: _normalizeClass(["transparent-button", {'bold': _ctx.lessTen}])
    }, _toDisplayString(_ctx.$t('duration.less_ten')), 3),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = (event) => _ctx.setFilteredDuration(event, 2)),
      class: _normalizeClass(["transparent-button", {'bold': _ctx.tenToFifteen}])
    }, _toDisplayString(_ctx.$t('duration.ten_to_fifteen')), 3),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = (event) => _ctx.setFilteredDuration(event, 3)),
      class: _normalizeClass(["transparent-button", {'bold': _ctx.moreFifteen}])
    }, _toDisplayString(_ctx.$t('duration.more_15')), 3)
  ]))
}