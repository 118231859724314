import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "icon-button-label"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "icon-button-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.dense)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["ww-icon-button", { 'raised': _ctx.raised, 'primary': _ctx.primary}]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args)))
      }, [
        _createElementVNode("i", {
          class: "svgicon",
          innerHTML: _ctx.icon
        }, null, 8, _hoisted_1),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: "ww-icon-button-dense",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args)))
      }, [
        _createElementVNode("i", {
          class: "svgicon",
          innerHTML: _ctx.icon
        }, null, 8, _hoisted_3),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ]))
}