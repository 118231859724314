
import { defineComponent } from 'vue'

export default defineComponent({
  name: "Select",
  components: {
  },  
  computed: {
  },
  props: {
      options: {
        type: Array,
        default: null
      },
      name: {
        type: String,
        default: 'select'
      },
      label: {
        type: String,
        default: 'select'
      },
      optionValue: {
        type: String,
        default: 'uid'
      },
      optionText: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxHeight: {
        type: Number,
        default: 200
      },
      value: {
        type: Number,
        default: 0
      }
  },
  data() {
    return {
      selected: {
        value: 0,
        text: '-' as String
      },
      listActive: false,
      listHeight: 0,
      listStyle: '',
      id: '',
      hasTransition: false,
      expanded: false,
      isBottomUp: false
    }
  },
  watch: {
    options() {
      const option = this.options.find((o: any) => o[this.optionValue] == this.selected.value ) as any
        if (option) {
          this.selected.text = option[this.optionText]
        }      
    },
    value(newVal : any, oldVal : any) { 
      if (newVal != oldVal) {
        this.selected.value = newVal;
        const option = this.options.find((o: any) => o[this.optionValue] == newVal ) as any
        if (option) {
          this.selected.text = option[this.optionText]
        }
      } 
    }
  },
  created() {
    this.id = this.getUniqe()
    const main = document.querySelector('main');
    if (main) {        
      main.addEventListener('scroll', () => {
        this.listStyle = 'height: ' + (this.expanded ? this.getListHeight() : '0') + 'px; ' + this.getContainerStyle()
      });
    }
    document.addEventListener('click', ev => {
      if (this.listActive) {
        const cPath = this.getClickPath(ev)
        //console.log('getClickPath', cPath)
        const selfClicked = cPath.find((item : HTMLElement) => {
            //console.log('el', item, item instanceof HTMLElement );
            return (item && item.classList && (item.classList.contains('customselect') || item.classList.contains('selectlist-container')))
        })
        if (!selfClicked) this.toggleList()
      }
    })
  },
  mounted() {
    //this.$nextTick(() => {
      const container = document.getElementById('wwrSelectContainer_' + this.id) as any;
      console.log('container', container);
      if (container && container?.parentNode?.nodeName != 'BODY') {
        document.body.appendChild(container);
      }
      this.listStyle = 'height: 0; '  + this.getContainerStyle()
    //});
  },
  methods: {
    handleSelectClick() {
      this.toggleList()
    },
    toggleList() {
      if (this.listActive) {
        this.collapseList()
      } else {
        this.expandList()
      }
      this.listActive = !this.listActive;
    },
    handleSelectListClick(ev : MouseEvent) {
      console.log('handleSelectListClick', ev, ev.target);
      const target = ev.target as HTMLElement
      if (target && target.classList.contains('selectable')) {
        this.selected.value = target.dataset['value'] as any
        this.selected.text = target.innerHTML.trim()
        this.toggleList()
        this.$emit('change', ev, this.selected.value)
      }
    },
    handleTransitionEnd() {
      this.hasTransition = false
    },
    collapseList() {
      this.hasTransition = true;
      //this.listStyle = 'height: ' + this.getListHeight() + 'px;'
      //this.hasPropertiesTransition = true;
      //this.$nextTick(() => {
        this.listStyle = 'height: 0; ' + this.getContainerStyle()
        this.expanded = false
      //});
    },
    expandList() {
      this.listStyle = 'height: 0; '  + this.getContainerStyle()
      this.hasTransition = true
      //this.hasPropertiesTransition = true;
      this.$nextTick(() => {
        this.listStyle = 'height: ' + this.getListHeight() + 'px; ' + this.getContainerStyle();
        this.expanded = true
      });
    },  
    getListHeight() {
      const dEl = this.$refs.domItems as any
      let height = dEl.getBoundingClientRect().height
      if (height > this.maxHeight) height = this.maxHeight
      return height
    },  
    getContainerStyle() {
      let style = ''
      const domSelect = this.$refs.domSelect as HTMLElement
      if (domSelect) {
        const brSelect = domSelect.getBoundingClientRect() as DOMRect
        style = 'left : ' + brSelect.left + 'px; '
        style += 'width: ' + brSelect.width + 'px; '
        if ((brSelect.top - this.getPageScroll() + this.getListHeight()) > window.innerHeight) {
          const domSelected = this.$refs.domSelected as HTMLElement
          if (domSelected) {
            const brc = domSelected.getBoundingClientRect() as DOMRect
            style += ' bottom: ' + (window.innerHeight - brSelect.bottom + brc.height) + 'px; top: auto; '
            this.isBottomUp = true
          }
        } else {
          style += ' top: ' + (brSelect.top + brSelect.height) + 'px; bottom: auto; '
          this.isBottomUp = false
        }
      }
      
      return style
    },
    getUniqe() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
          var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
      });
    },
    getPageScroll() {
      let yScroll = 0;
      if (window.pageYOffset) {
        yScroll = window.pageYOffset;
      } else if (document.documentElement && document.documentElement.scrollTop) {
        yScroll = document.documentElement.scrollTop;
      } else if (document.body) {
        yScroll = document.body.scrollTop;
      }
      return yScroll;
    }, 
    getClickPath(ev : any) {
      let cPath
      if ("composedPath" in ev) {
        cPath = ev.composedPath();
      } else if ("path" in ev) {
        cPath = ev.path;
      } else {
        const path = [];
        let currentElem = ev.target;
        while (currentElem) {
          path.push(currentElem);
          currentElem = currentElem.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
          path.push(document);
        if (path.indexOf(window) === -1) path.push(window);
        cPath = path;
      }  
      return cPath
    }  
  },
})
