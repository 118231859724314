import router from "./router";
import { UserController, MedSessionsController, ApplePurchaseController} from "@/controller";
import { setLoading, unsetLoading } from "@/utils/loading";

router.beforeEach(async (to:any, from:any, next:any) => {
    //console.log('permissions to path', to);
    const tokenStored = localStorage.getItem('ALPrelax_applepurchase_token')
    if(to.path == '/' || to.path == '/login' || to.path.includes('/willkommen') || to.path.includes('/promotion') || to.path.includes('/wohlfuehlen') || to.path.includes('/registerform') || to.path == '/loginform' || to.path.includes('/confirmemail') || to.path == '/forgotpassword' || to.path.includes('/resetpassword') || to.path.includes('/privacy')){
        if (to.query.externalPurchaseToken && !tokenStored) {
            //create localstorage entry
            localStorage.setItem('ALPrelax_applepurchase_token', to.query.externalPurchaseToken as string)
        }        
        next()
      } else {
        setLoading()
        if(!UserController.user){
            await UserController.getUser();
            if (UserController.user) {
                if (to.query.externalPurchaseToken || tokenStored) {
                    const token = to.query.externalPurchaseToken ? to.query.externalPurchaseToken as string : tokenStored as string
                    const res = await ApplePurchaseController.createToken(token)
                    if (res?.data?.data?.token) {
                        localStorage.removeItem('ALPrelax_applepurchase_token')
                    }
                }
            } else if (to.query.externalPurchaseToken && !tokenStored) {
                localStorage.setItem('ALPrelax_applepurchase_token', to.query.externalPurchaseToken)
            }
                //console.log('permissions UserController.user: ', UserController.user);
                //WS TODO: evtl. einschränken auf eine bestimmte route z.B. nur bei "home"
            if (UserController.user && UserController.user.choosenAboId && UserController.user.choosenAboCode) {
                next({ path: "/manageabo/" + UserController.user.choosenAboCode});
            }
        }
        if(MedSessionsController.medSessions.length == 0 && (to.path == '/home' || to.path == '/meditations')){
            await MedSessionsController.getMedSessions()
        }
        unsetLoading()
        next()
    }
})

