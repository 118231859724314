
import { defineComponent } from 'vue';

export default defineComponent ({
    name: "CardMedSession",
    data(){
        return{
            icons:{
                check: '<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_122_1486)"><path d="M1.36353 6.93334C2.49534 8.372 3.6408 9.828 4.77262 11.2667C7.73171 8.09467 10.6772 4.90534 13.6363 1.73334" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_122_1486"><rect width="15" height="13" fill="#fff"/></clipPath></defs></svg>',
            }
        }
    },
    props:{
        allSessionsAvailable: {
            type: Boolean,
            default: false
        },
        medSession:{
            type: Object,
            default:  null
        },
        title:{
            type: String,
            default: "Titel als Prop"
        },
        width: {
            type: Number,
            defaul: 100
        }
    },
    computed:{
        isLoading(){
            if(!this.medSession){
                return true
            }
            return false
        },
        isAvailable(){
            if(this.medSession.available == 1){
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        convertSecondsToTime(seconds : any){
            const result = new Date(seconds * 1000).toISOString().slice(14, 19);
            return result
        }
    }
})
