import axios from "axios";
import { Api } from "../api";

const UserRepository = {

    /**
     * checkLogin
     * 
     * @param {Object} 
     * 
     * @returns {Object} server response
     */  
    async checkLogin() {
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.CHECK_LOGIN_ACTION);
            //console.log('checkLogin res', res)
            return res;
        } catch(e){
            console.log('checkLogin',e);
            return {}
        }
        
    },
    /**
     * createUser
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async createUser(postData : object) {
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.CREATEUSER_ACTION, postData);
            //console.log('createUserr res', res)
            return res;
        } catch(e){
            console.log('createUser err',e);
            return {}
        }
        
    },
    /**
     * editUser
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
     async editUser(postData : object) {
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.EDIT_USER_ACTION, postData);
            //console.log('edit user res', res)
            return res            
        } catch(e){
            console.log('editUser err',e);
            return {}
        }  
    },
    /**
     * loginUser
     * 
     * @param {Object} loginData
     * 
     * @returns {Object} server response
     */  
    async loginUser(loginData : object) {
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.LOGIN_ACTION, loginData);
            //console.log('loginResponse', res)
            return res;
        } catch(e){
            console.log('loginUser error:', e);
            return {}
        }
    },
     /**
     * logoutUser
     *  
     * @returns {Object} server response
     */  
    async logoutUser(){
        try{
            const postData = "logintype=logout";
            const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.LOGOUT_ACTION, postData)
            //console.log('logoutUser', res)
            return res
            
        }catch(e){
            console.log('logoutUser error:', e);
            return {}
        }
    },
    /**
     * deleteAccount
     *  
     * @returns {Object} server response
     */  
    async deleteAccount(){
        try{
            const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.DELETE_ACCOUNT_ACTION,)
            //console.log('deleteAccount', res)
            return res
            
        }catch(e){
            console.log('deleteAccount error:', e);
            return {}
        }
    },
     /**
     * getUser
     * 
     * 
     * @returns {Object} server response
     */ 
    async getUser(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_USER_ACTION);
            //console.log('getUser Response', res)
            return res
        }  catch(e){
            console.log('getUser error:', e);
            return {}
        }
    },
     /**
     * changePassword
     * 
     * @param {Object} formData
     * 
     * @returns {Object} server response
     */  
      async changePassword(formData : object) {
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.CHANGEPASSWORD_ACTION, formData);
            //console.log('changePassword res', res)
            return res;
        } catch(e){
            console.log('changePassword error:', e);
            return {}
        }

    },
    /**
     * sendPasswordLink
     * 
     * @param {String} email
     * 
     * @returns {Object} server response
     */ 
    async sendPasswordLink(email:string){
        try {
            const res = await axios.post(Api.BACKEND_URL + Api.SENDPASSWORDLINK_ACTION, email);
            //console.log("sendPasswordLink res", res)
            return res;
        } catch(e){
            console.log('sendPasswordLink error:', e)
            return {}
        }
    },
     /**
     * resetPassword
     * 
     * @param {String} email
     * 
     * @returns {Object} server response
     */ 
      async resetPassword(email:string){
        try {
            const res = await axios.post(Api.BACKEND_URL + Api.RESET_PASSWORD_ACTION, email);
            //console.log("resetPassword res", res)
            return res;
        } catch(e){
            console.log('resetPassword error:', e)
            return {}
        }
    },
     /**
     * confirmUserMail
     *    
     * 
     * @param {Object} hash
     * 
     * @returns {Object} server response
     */ 
      async confirmUserMail(hash : any) {
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.CHECK_EMAIL_ACTION, hash);
            //console.log('confirmUserMail res', res)
            return res;
        } catch(e){
            console.log('confirmUserMail error:', e);
            return {}
        }
    },

    /**
     * getCountries
     * 
     * @returns {Object} server response
     */  
    async getCountries() {
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.COUNTRIES_ACTION);
            //console.log('getCountries res', res)
            return res;
        } catch(e){
            console.log('getCountries',e);
            return {}
        }
        
    },    

     /**
     * updateBilling
     * 
     * @param {Object} billingData
     * 
     * @returns {Object} server response
     */ 
      async updateBilling(billingData:Object){
        try {
            const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_BILLING_ACTION, billingData);
            //console.log("updateBilling res", res)
            return res;
        } catch(e){
            console.log('updateBilling error:', e)
            return {}
        }
    },    
} 
export default UserRepository;