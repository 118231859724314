import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ar-dialog-content" }
const _hoisted_2 = {
  key: 0,
  class: "ar-dialog-header"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "ar-dialog-text" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "ar-dialog-actions" }
const _hoisted_7 = {
  key: 1,
  class: "ar-field checkbox"
}
const _hoisted_8 = { for: "dontShowAgain" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "ar-dialog",
    class: _normalizeClass(["ar-dialog", _ctx.getDisplayClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isModal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "ar-icon-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
            }, [
              _createElementVNode("i", {
                class: "svgicon",
                innerHTML: _ctx.icon.close
              }, null, 8, _hoisted_3)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", { innerHTML: _ctx.text }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args))),
          class: "ar-button btn-primary"
        }, _toDisplayString(_ctx.buttonText), 1),
        (!_ctx.hideCancelButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args))),
              class: "ar-button-dense btn-cancel"
            }, _toDisplayString(_ctx.$t('button.cancel')), 1))
          : _createCommentVNode("", true),
        (_ctx.enableDontShowAgain)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: "dontShowAgain",
                name: "dontShowAgain",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dontShowAgain) = $event))
              }, null, 512), [
                [_vModelCheckbox, _ctx.dontShowAgain]
              ]),
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('dialog.dontShowAgain')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}