import axios from "axios";
import { Api } from "../api";

const InvoiceRepository = {

   /**
     * downloadInvoice
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async downloadInvoice(postData : object) {
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.DOWNLOAD_INVOICE_ACTION, postData);
            console.log('download invoice res', res)
            return res            
        } catch(e){
            console.log('download invoice',e);
            return {}
        }  
    },
}

export default InvoiceRepository;