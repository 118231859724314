import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ww-collapse-list-item-text" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  key: 0,
  class: "ww-collapse-list-item-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ww-collapse-list-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggleActive && _ctx.handleToggleActive(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.subtitle), 1)
      ]),
      (_ctx.active)
        ? (_openBlock(), _createBlock(_component_IconButton, {
            key: 0,
            icon: _ctx.icons.arrowdown
          }, null, 8, ["icon"]))
        : (_openBlock(), _createBlock(_component_IconButton, {
            key: 1,
            icon: _ctx.icons.arrowforward
          }, null, 8, ["icon"]))
    ]),
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}