import axios from "axios";
import { Api } from "../api";

const AboRepository = {

    /**
     * getAbos
     * 
     * @returns {Object} server response
     */  
    async getAbos(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GETABOS_ACTION );
            //console.log('getAbos res', res)
            return res
        } catch (e){
            console.log('getAbos error',e);
            return {};
        }
   },

    /**
     * getHiddenAbo
     * 
     * @returns {Object} server response
     */  
    async getHiddenAbo(aboId: any){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GETHIDDENABO_ACTION + '&tx_wwalprelax_abo[aboId]=' + aboId);
            //console.log('getAbos res', res)
            return res
        } catch (e){
            console.log('getAbos error',e);
            return {};
        }
   },

   /**
     * changeAbo
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async changeAbo(postData : object) {
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.CHANGE_ABO_ACTION, postData);
            //console.log('changeAbo res', res)
            return res            
        } catch(e){
            console.log('changeAbo',e);
            return {}
        }  
    },


   /**
     * manageAbo
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async manageAbo(postData : object) {
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.MANAGE_ABO_ACTION, postData);
            //console.log('manageAbo res', res)
            return res            
        } catch(e){
            console.log('manageAbo',e);
            return {}
        }  
    },

   /**
     * cancelAbo
     * 
     * @returns {Object} server response
     */  
    async cancelAbo() {
        try{
            const res = await axios.create().get(Api.BACKEND_URL + Api.CANCEL_ABO_ACTION);
            //console.log('cancelAbo res', res)
            return res            
        } catch(e){
            console.log('cancelAbo',e);
            return {}
        }  
    },

   /**
     * redoCancelAbo
     * 
     * @returns {Object} server response
     */  
    async redoCancelAbo() {
        try{
            const res = await axios.create().get(Api.BACKEND_URL + Api.REDO_CANCEL_ABO_ACTION);
            //console.log('redoCancelAbo res', res)
            return res            
        } catch(e){
            console.log('redoCancelAbo',e);
            return {}
        }  
    },    

   /**
     * redoChangeAbo
     * 
     * @returns {Object} server response
     */  
    async redoChangeAbo() {
        try{
            const res = await axios.create().get(Api.BACKEND_URL + Api.REDO_CHANGE_ABO_ACTION);
            //console.log('redoChangeAbo res', res)
            return res            
        } catch(e){
            console.log('redoChangeAbo',e);
            return {}
        }  
    }, 

   /**
     * joinCompany
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async joinCompany(postData : object) {
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.JOIN_COMPANY_ACTION, postData);
            //console.log('joinCompany res', res)
            return res            
        } catch(e){
            console.log('joinCompany',e);
            return {}
        }  
    },     
}

export default AboRepository;