
import { defineComponent } from 'vue';
import store from '@/store/index';
import { IconInline } from '@/ui/index';

export default defineComponent({
    name: "PasswordInput",
    components: { IconInline },
    props:{
        placeholder:{
            type: String,
            defaut: ""
        },
        tabindex: {
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            store,
            input: '',
            inputType: 'password',
            icons:{
                watch: '<svg width="34" height="18" viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="outline" d="M33 9C31.5314 10.7009 25.7359 17 17 17C15.0703 17 7.64824 16.6924 1 9C2.46546 7.30219 8.26096 1 17 1C18.9297 1 26.3518 1.30757 33 9Z" stroke="#163D64" stroke-width="1.5" stroke-miterlimit="10"/><path class="point" d="M17.0001 15.2223C19.9456 15.2223 22.3334 12.4365 22.3334 9.00005C22.3334 5.56362 19.9456 2.77783 17.0001 2.77783C14.0546 2.77783 11.6667 5.56362 11.6667 9.00005C11.6667 12.4365 14.0546 15.2223 17.0001 15.2223Z" fill="#163D64"/></svg>',
                watchToggle: '<svg width="32" height="17" viewBox="0 0 32 17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_540_1244)"><path d="M31.25 8.35C29.85 10.02 24.31 16.2 15.95 16.2C14.1 16.2 7.01002 15.9 0.650024 8.35C2.06002 6.69 7.60002 0.5 15.95 0.5C17.8 0.5 24.89 0.8 31.25 8.35Z" stroke="#163D64" stroke-miterlimit="10"/><path d="M1.31 15.78L30.77 0.849976" stroke="#163D64" stroke-miterlimit="10" stroke-linecap="round"/><path d="M21.51 5.20996C20.38 3.20996 18.25 1.95996 15.95 1.95996C12.43 1.95996 9.56 4.82996 9.56 8.34996C9.56 9.18996 9.72 10.01 10.04 10.78L10.24 11.27L21.76 5.65996L21.5 5.19996L21.51 5.20996Z" fill="#163D64"/><path d="M21.21 5.70996L10.63 10.86C11.57 12.86 13.6 14.25 15.96 14.25C19.21 14.25 21.85 11.61 21.85 8.35996C21.85 7.40996 21.62 6.50996 21.22 5.70996" stroke="#163D64" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_540_1244"><rect width="31.9" height="16.71" fill="white"/></clipPath></defs></svg>',
             }
        }
    },
    computed:{
        icon(){
            if(this.inputType=='password'){
                return this.icons.watchToggle
            } else {
                return this.icons.watch
            }
        }
    },
    watch:{
        input(){
            this.$emit('onChange', this.input)
        }
    },
    methods:{
        handleToggleInputType() {
            if(this.inputType=='password'){
                this.inputType='text'
            } else {
                this.inputType='password'
            }
        }
    }
})
