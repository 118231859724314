
import { AboRepository } from "../repository";

const AboController = {
    abos: [],

    async getAbos() {
        const res : any = await AboRepository.getAbos();
        if(res){
            if(res.data?.success){
                this.abos = res.data?.data;
                return res
            } 
            return res;
        }
    },
    async getHiddenAbo(aboId: any) {
        const res : any = await AboRepository.getHiddenAbo(aboId);
        if(res){
            if(res.data?.success){
                    //add this one to the abos?
                return res.data?.data
            } 
            return res;
        }
    },
    async changeAbo(aboId : any, password : string) {
        const postData : any = `tx_wwalprelax_abo[abo]=${aboId}&tx_wwalprelax_abo[password]=${password}`
        const res : any = await AboRepository.changeAbo(postData);
        return res
    },    
    async manageAbo(aboId : any) {
        const postData : any = `tx_wwalprelax_abo[abo]=${aboId}`
        const res : any = await AboRepository.manageAbo(postData);
        return res
    },
    async cancelAbo() {
        //const postData : any = `tx_wwalprelax_abo[abo]=${aboId}`
        const res : any = await AboRepository.cancelAbo();
        return res
    },
    async redoCancelAbo() {
        //const postData : any = `tx_wwalprelax_abo[abo]=${aboId}`
        const res : any = await AboRepository.redoCancelAbo();
        return res
    },
    async redoChangeAbo() {
        //const postData : any = `tx_wwalprelax_abo[abo]=${aboId}`
        const res : any = await AboRepository.redoChangeAbo();
        return res
    },
    async joinCompany(companyCode : string) {
        const postData : any = `tx_wwalprelax_abo[companyCode]=${companyCode}`
        const res : any = await AboRepository.joinCompany(postData);
        return res
    },       
}
export default AboController;