import axios from "axios";
import { Api } from "../api";

const PaymentRepository = {

    /**
     * payments
     * 
     * @returns {Object} server response
     */  
    async payments(){
        try {
            const res = await axios.get(Api.BACKEND_URL + Api.PAYMENTS_ACTION );
            //console.log('PaymentRepository:payments:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:payments:error', e);
            return {};
        }
   },

    /**
     * preparePayment
     * 
     * @param {Object} postData
     *
     * @returns {Object} server response
     */  
    async preparePayment(postData : object){
        try {
            const res = await axios.post(Api.BACKEND_URL + Api.PAYMENT_PREPARE_ACTION, postData );
            //console.log('PaymentRepository:preparePayment:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:preparePayment:error', e);
            return {};
        }
   },

    /**
     * paymentData
     * 
     * @returns {Object} server response
     */  
    async paymentData(){
        try {
            const res = await axios.get(Api.BACKEND_URL + Api.PAYMENT_DATA_ACTION );
            //console.log('PaymentRepository:paymentData:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:paymentData:error', e);
            return {};
        }
   },

    /**
     * checkPaymentStatus
     * 
     * @param {Object} postData
     *
     * @returns {Object} server response
     */  
    async checkPaymentStatus(postData : object){
        try {
            const res = await axios.post(Api.BACKEND_URL + Api.PAYMENT_CHECK_PAYMENT_ACTION, postData );
            //console.log('PaymentRepository:checkPaymentStatus:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:checkPaymentStatus:error', e);
            return {};
        }
   },   

   /**
     * manageAbo
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async manageAbo(postData : object) {
        try {
            const res = await axios.create().post(Api.BACKEND_URL + Api.MANAGE_ABO_ACTION, postData);
            //console.log('manageAbo res', res)
            return res            
        } catch(e){
            console.log('manageAbo',e);
            return {}
        }  
    },

    /**
     * confirmPaymentAutorization
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    /* async confirmPaymentAutorization(postData : object) {
        try {
            const res = await axios.create().post(Api.BACKEND_URL + Api.PAYMENT_CONFIRM_AUTHORIZATION_ACTION, postData);
            console.log('confirmPaymentAutorization res', res)
            return res            
        } catch(e){
            console.log('confirmPaymentAutorization',e);
            return {}
        }  
    }, */
}

export default PaymentRepository;