import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["ar-page-header", _ctx.headerClass])
  }, [
    _createElementVNode("button", {
      class: "button-logo",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleHomeClick && _ctx.handleHomeClick(...args)))
    }, [
      _createElementVNode("i", {
        innerHTML: _ctx.logo,
        class: "page-header-logo"
      }, null, 8, _hoisted_1)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}