const DeviceService = { 

  browser: '',
  serviceWorker: false as Boolean,
  appleMobile: false as Boolean,
  isInWebAppiOS : false as Boolean,

  detect() {

    const ng: any = window.navigator
    const userAgent = ng.userAgent

    if (userAgent.match(/chrome|chromium|crios/i)) {
      this.browser = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      this.browser = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      this.browser = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      this.browser = "Opera";
    } else if (userAgent.match(/edg/i)) {
      this.browser = "Edge";
    } else if (userAgent.match(/android/i)) {
      this.browser = "Android";
    } else if (userAgent.match(/iphone/i)) {
      this.browser = "iPhone";
    } else {
      this.browser = "Unknown";
    }

    this.serviceWorker = ("serviceWorker" in ng)
    this.appleMobile = (ng.platform === 'iPhone' || ng.platform === 'iPad')
    this.isInWebAppiOS = (ng.standalone == true)
  }

}

DeviceService.detect()

export default DeviceService;

      
