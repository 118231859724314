import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "go-back-btn"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "go-back-text" }
const _hoisted_4 = {
  key: 1,
  class: "close-btn"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "close-text" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["fixed-header-content", 'view-' + _ctx.routeName])
    }, [
      (_ctx.fixedHeaderBackButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleGoBack && _ctx.handleGoBack(...args)))
            }, [
              _createElementVNode("i", {
                innerHTML: _ctx.icons.arrow,
                class: "arrow-go-back"
              }, null, 8, _hoisted_2),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('button.goback')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.fixedHeaderCloseButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
            }, [
              _createElementVNode("i", {
                innerHTML: _ctx.icons.close,
                class: "close"
              }, null, 8, _hoisted_5),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('button.close')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.fixedHeaderLogo)
        ? (_openBlock(), _createElementBlock("i", {
            key: 2,
            innerHTML: _ctx.icons.logo,
            class: "header-logo"
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true),
      false
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "ar-icon-button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleGoToFAQs && _ctx.handleGoToFAQs(...args)))
          }, [
            _createElementVNode("i", {
              class: "svgicon",
              innerHTML: _ctx.icons.questionmark
            }, null, 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true),
      (_ctx.fixedHeaderLogoutButton)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 4,
            class: "logout-btn",
            text: _ctx.$t('button.logout'),
            dense: true,
            icon: _ctx.icons.logout,
            onHandleClicked: _ctx.logoutUser
          }, null, 8, ["text", "icon", "onHandleClicked"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error,
      aboveBottomMenu: true
    }, null, 8, ["show", "text", "error"])
  ]))
}