import axios from "axios";
import { Api } from "../api";

const ApplePurchaseRepository = {
    async createToken(postData:any){
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.APPLE_PURCHASE_TOKEN_CREATE, postData);
            console.log('createToken Response', res)
            return res
        } catch(e) {
            console.log('createToken error', e);
            return {};
        }
    },
}
export default ApplePurchaseRepository;