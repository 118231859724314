
import { CategoryRepository } from "../repository";

const CategoriesController = {
    categories: [],

    async getCategories() {
        const res : any = await CategoryRepository.getCategories();
            if(res.data.success){
                this.categories = res.data?.data;
                return res
            } 
        return res;
    }
}
export default CategoriesController;