import axios from "axios";
import { Api } from "../api";

const PlayStatisticRepository = {

     /**
     * create
     *    
     * 
     * @param {Object} sessionID
     * 
     * @returns {Object} server response
     */ 
     async create(sessionID : any) {
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.CREATE_PLAYSTATISTIC_ACTION, sessionID);
            console.log('PlayStatisticRepository create Response', res)
            return res;
        } catch(e){
            console.log('createStatistic error:', e);
            return {}
        }
    },   
}
export default PlayStatisticRepository;