import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import "@/assets/scss/app.scss"
import translations from "./resources/translations";
import store from "./store";
import { createI18n } from 'vue-i18n';
import './registerServiceWorker';
import "./permission.ts";
import "@/utils/resizeService.ts";

const i18n = createI18n({
    locale: "de",
    fallbackLocale: "de",
    messages: translations
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.mount('#app');
app.use(store);
