
import { useEventBus } from '@vueuse/core'
import { alprelaxEventKey } from '@/bus/eventKey'

const ResizeService = {
    eventBus: useEventBus(alprelaxEventKey),
    resizeTimeout: false,
    resizeDelta: 200,
    resizeTime: new Date(1, 1, 2000, 12, 0, 0) as any,
    height: window.innerHeight * 0.01,

    listener: () => {
        ResizeService.resizeTime = new Date()
        if (ResizeService.resizeTimeout === false) {
            ResizeService.resizeTimeout = true
            setTimeout(() => ResizeService.checkResize(), ResizeService.resizeDelta);
        }
    },

    checkResize: () => {
        const d : any = new Date()
        
        if (d - ResizeService.resizeTime < ResizeService.resizeDelta) {
            setTimeout(() => ResizeService.checkResize(), ResizeService.resizeDelta);
            return;
        }
        ResizeService.resizeTimeout = false;
        ResizeService.height = window.innerHeight
        ResizeService.eventBus.emit("onResize", ResizeService.height);        
    },

    init: () => {
        window.removeEventListener("resize", ResizeService.listener)
        window.addEventListener("resize", ResizeService.listener)

            //initial resize
        ResizeService.checkResize()
    }

}

ResizeService.init();

export default ResizeService;