
import { NewsletterRepository } from "../repository";

const NewsletterController = {
    faqs: [],

    async subscribeNewsletter() {
        const res : any = await NewsletterRepository.subscribeNewsletter();
            if(res.data.success){
                this.faqs = res.data?.data;
                return res
            } 
        return res;
    },

    async unsubscribeNewsletter() {
        const res : any = await NewsletterRepository.unsubscribeNewsletter();
            if(res.data.success){
                this.faqs = res.data?.data;
                return res
            } 
        return res;
    }    
}
export default NewsletterController;