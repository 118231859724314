
import { defineComponent } from 'vue';
import store from '@/store/index';
import { IconButton } from '@/ui/index';

export default defineComponent({
    name: "CollapseListItem",
    components: { IconButton },
    props:{
        title:{
            type: String, 
            default: ""
        },
        subtitle:{
            type: String, 
            default: ""
        },
    },
    data(){
        return{
            store, 
            active: false,
            icons: {
                arrowdown: '<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.999998L5.95 6.25995H6.01L10.96 0.999998" stroke="#B9C7D1" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                arrowforward: '<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.740059 11.26L6.31006 6.31001V6.25001L0.740059 1.30001" stroke="#B9C7D1" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            }
        }
    },
    methods:{
        handleToggleActive(){
            this.active = !this.active
        }
    }
})
