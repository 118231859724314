import axios from "axios";
import { Api } from "../api";

const NewsletterRepository = {
 /**
     * subscribeNewsletter
     * 
     * 
     * @returns {Object} server response
     */  
    async subscribeNewsletter(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.SUBSCRIBE_NEWSLETTER_ACTION );
            console.log('subscribeNewsletter res', res)
            return res
        } catch (e){
            console.log('subscribeNewsletter error',e);
            return {};
        }
    },

    /**
     * unsubscribeNewsletter
     * 
     * 
     * @returns {Object} server response
     */  
    async unsubscribeNewsletter(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.UNSUBSCRIBE_NEWSLETTER_ACTION );
            console.log('unsubscribeNewsletter res', res)
            return res
        } catch (e){
            console.log('unsubscribeNewsletter error',e);
            return {};
        }
   }
}

export default NewsletterRepository;