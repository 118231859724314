import { UserRepository } from "../repository";
import { DataService } from '../utils';
import router from "@/router";
import { useEventBus } from '@vueuse/core'
import { alprelaxEventKey } from '@/bus/eventKey'

const UserController = {
    user: null as any,
    waitForUser: false as Boolean,
    router,
    countries: [] as Array<any>,
    eventBus: useEventBus(alprelaxEventKey),
    routeToRedirect: '',

    async getUser(){
        this.waitForUser = true
        const response : any = await UserRepository.getUser();
        if(response.data.success){
            this.user = response.data.data
        } else {
            this.routeToRedirect = window.location.pathname
            this.router.push('/login')
        }
        this.waitForUser = false
        this.eventBus.emit('onUserFetched')
        return response.data.data
    },
    async checkLogin() {
        const res : any = await UserRepository.checkLogin();
        return res
    },
    async createUser(userData : any) {
        userData = DataService.buildPostData(userData, 'tx_wwalprelax_user', false);
        const res : any = await UserRepository.createUser(userData);
        return res
    },
    async logintUser(userData : any){
        userData = DataService.stringify(userData);
        const response : any = await UserRepository.loginUser(userData);
        return response
    },
    async logoutUser(){
        const response : any = await UserRepository.logoutUser();
        return response
    },
    async deleteAccount(){
        const response : any = await UserRepository.deleteAccount();
        return response
    },
    async editUser(formData : any) {
        const postData : any = `tx_wwalprelax_user[user][__identity]=${formData.uid}&tx_wwalprelax_user[user][firstName]=${formData.firstName}&tx_wwalprelax_user[user][lastName]=${formData.lastName}&tx_wwalprelax_user[user][username]=${formData.username}`
        const res : any = await UserRepository.editUser(postData);
        this.user = res.data.data
        return res
    },
    async changePassword(formData : any){
        formData = DataService.buildPostData(formData,'tx_wwalprelax_user', true);
        const response : any = await UserRepository.changePassword(formData);
        return response
    },
    async sendPasswordLink(email:string){
        const postData = `tx_wwalprelax_user[username]=${email}`
        const response : any = await UserRepository.sendPasswordLink(postData);
        return response
    },
    async resetPassword(postData: any){
        postData = DataService.buildPostData(postData,'tx_wwalprelax_user', true);
        const response : any = await UserRepository.resetPassword(postData);
        return response
    },
    async confirmUserMail(hash:any){
        hash = DataService.buildPostData(hash, 'tx_wwalprelax_user', false);
        const response : any = await UserRepository.confirmUserMail(hash);
        return response
    },
    async getCountries(){
        const response : any = await UserRepository.getCountries();
        if (response && response.data) {
            this.countries = response.data.data
        }
        return this.countries
    },
    async updateBilling(billingData : any) {
        billingData = DataService.buildPostData(billingData, 'tx_wwalprelax_user', false);
        const res : any = await UserRepository.updateBilling(billingData);
        return res
    },
}

export default UserController;