import axios from "axios";
import { Api } from "../api";

const FavoritRepository = {
    async getFavorits(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GETFAVORITES_ACTION)
            return res
        } catch (e) {
            console.log('getFavorits', e);
            return {}
        }
    },
    async addFavorit(postData:any){
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.ADD_FAVORIT_ACTION, postData);
            console.log("'addFavorit Response", res)
            return res
        } catch(e) {
            console.log('addFavorit error', e);
            return {};
        }
    },
    async removeFavorit(postData:any){
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.REMOVE_FAVORIT_ACTION, postData);
            console.log("'removeFavorit Response", res)
            return res
        } catch(e) {
            console.log('removeFavorit error', e);
            return {};
        }
    },
}
export default FavoritRepository;