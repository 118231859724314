import axios from "axios";
import { Api } from "../api";

const CategoryRepository = {
    /**
     * getCategories
     * 
     * @returns {Object} server response
     */  
    async getCategories(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GETCATEGORIES_ACTION );
            console.log('getCategories res', res)
            return res
        } catch (e){
            console.log('getCategories error',e);
            return {};
        }
   }
}
export default CategoryRepository;