
import { defineComponent } from "vue"
export default defineComponent({
    name: "Snackbar",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: '',
        },
        error:{
            type: Boolean,
            default: false
        },
        aboveBottomMenu:{
            type: Boolean,
            default: false
        },
    },
})
