
import { PlayStatisticRepository } from "../repository";
import { MedSessionsController } from "./index";

const PlayStatisticController = {
    async create(sessionId:any){
        const now = Date.now().toString().slice(0, -3)
        const postData =  `tx_wwalprelax_playstatistic[session]=${sessionId}`;
        const res : any = await PlayStatisticRepository.create(postData);
        return res
    }
}
export default PlayStatisticController;