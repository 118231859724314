import { createStore } from 'vuex';

const store = createStore({
    state () {
        return {
            currentPage: 1,
            hideNavbar: false
        }
    },
    getters:{
        getHideNavbar(state:any){
            return state.hideNavbar
        }
    },
    mutations: {
        goToPage(state: any, pageNumber : any){
            state.currentPage = pageNumber
        },
        setHideNavbar(state: any, active:any){
            state.hideNavbar = active
        }
        /*
        goToStart(state: any, pageNumber : any){
            state.currentPage = pageNumber
        },
        goToMeditation(state: any, pageNumber : any){
            state.currentPage = pageNumber
        }*/
    }
})

export default store;