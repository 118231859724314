import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-menu" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "nav-element-btn",
      onClick: _cache[0] || (_cache[0] = (event) => _ctx.handleItemClick(event, '/home', 1))
    }, [
      _createElementVNode("i", {
        innerHTML: _ctx.icons.logo,
        class: "nav-icon"
      }, null, 8, _hoisted_2),
      _createElementVNode("span", {
        class: _normalizeClass({'bold': _ctx.checkActivView('/home')})
      }, _toDisplayString(_ctx.$t('nav.start')), 3)
    ]),
    _createElementVNode("button", {
      class: "nav-element-btn",
      onClick: _cache[1] || (_cache[1] = (event) => _ctx.handleItemClick(event, '/meditations', 2))
    }, [
      _createElementVNode("i", {
        innerHTML: _ctx.icons.meditations,
        class: "nav-icon"
      }, null, 8, _hoisted_3),
      _createElementVNode("span", {
        class: _normalizeClass({'bold': _ctx.checkActivView('/meditations')})
      }, _toDisplayString(_ctx.$t('nav.meditations')), 3)
    ]),
    _createElementVNode("button", {
      class: "nav-element-btn",
      onClick: _cache[2] || (_cache[2] = (event) => _ctx.handleItemClick(event, '/favorits', 3))
    }, [
      _createElementVNode("i", {
        innerHTML: _ctx.icons.heart_outline,
        class: "nav-icon"
      }, null, 8, _hoisted_4),
      _createElementVNode("span", {
        class: _normalizeClass({'bold': _ctx.checkActivView('/favorits')})
      }, _toDisplayString(_ctx.$t('nav.favorits')), 3)
    ]),
    _createElementVNode("button", {
      class: "nav-element-btn",
      onClick: _cache[3] || (_cache[3] = (event) => _ctx.handleItemClick(event, '/account', 4))
    }, [
      _createElementVNode("i", {
        innerHTML: _ctx.icons.account,
        class: "nav-icon"
      }, null, 8, _hoisted_5),
      _createElementVNode("span", {
        class: _normalizeClass({'bold': _ctx.checkActivView('/account')})
      }, _toDisplayString(_ctx.$t('nav.account')), 3)
    ])
  ]))
}