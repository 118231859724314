
import { PaymentRepository } from "../repository";
import { DataService } from '../utils';

type Data = {
    success: boolean, 
    data: Array<never>,
}

type Response = {
    data?: Data
}

const PaymentController = {
    checkoutId: "",
    scriptUrl: "",
    resourcePath: '',
    
    payments: [], 
    
    payment: {  
        brands: "",
        prepared: false,
        shopperResultUrl: "",    
        statusResult: {
            result: {
                code: "",
                description: ""
            },
            timestamp: ""
        },
        successfully: false,
        pending: false
    },

    async paymentData() {
        const res : any = await PaymentRepository.paymentData() as Response;
        //console.log("PaymentController:paymentData:res", res);
        if(res){
            if(res.data?.success){
                this.payment.brands = res.data?.data?.brands;
                this.payment.shopperResultUrl = res.data?.data?.resultUrl;              
                return res
            } 
            return '';
        } else {
            return ''
        }
    },

    async getPayments() {
        const res : any = await PaymentRepository.payments() as Response;
        //console.log("PaymentController:payments:res", res);
        if(res){
            if(res.data?.success){
                this.payments = res.data.data;
                return res
            } 
            return '';
        } else {
            return ''
        }
    },    

    async preparePayment(paymentData: any) {
        paymentData = DataService.buildPostData(paymentData, 'tx_wwalprelax_payment', false );
        const res : any = await PaymentRepository.preparePayment(paymentData) as Response;
        //console.log("PaymentController:preparePayment:res", res);
        if(res){
            if(res.data?.success){
                this.checkoutId = res.data?.data.checkoutId;
                this.scriptUrl = res.data?.data.scriptUrl;
                this.addPaymentScript();
                this.payment.prepared = true;
                return res
            } 
            return res;
        }
    },

    async checkPaymentStatus(paymentData: any) {
        paymentData = DataService.buildPostData(paymentData, 'tx_wwalprelax_payment', false );
        const res : any = await PaymentRepository.checkPaymentStatus(paymentData) as Response;
        //console.log("PaymentController:checkPaymentStatus:res", res);
        if(res){
            if(res.data?.success){
                this.payment.statusResult = res.data.data.status
                console.log("PaymentController:checkPaymentStatus:statusResult", this.payment.statusResult);
                this.checkPaymentResult()
                return res
            } 
            return res;
        }
    },

    /* async confirmPaymentAutorization(postData:any){
        postData = DataService.buildPostData(postData, 'tx_wwalprelax_alprelax', false );
        const res : any = await PaymentRepository.confirmPaymentAutorization(postData) as Response;

    }, */

    reloadPayment() {
        this.addPaymentScript();
    },

    addPaymentScript() {
        let s : any = document.getElementById('paymentCustomization');
            //settings
        if (s) s.remove();
        s = document.createElement('script');
        s.id = 'paymentCustomization';
        s.innerHTML = `
            /* create custom UI */
            function ww_wrapElement(element) {
                var id = element.id;
                var wrapId = "wrap_" + id;
                var dataPayment = id.substr(0, id.indexOf('_'));
                const nel = document.createElement('div');
                nel.id = wrapId;
                element.parentNode.insertBefore(nel, element);
                nel.dataset['paymentMethod'] = dataPayment;
                nel.appendChild(element);
                return nel;
            }

            wpwlPaymentAdvice = {
                "card": "Ich autorisiere ALPrelax, diese Zahlungsmethode zu speichern sowie die Zahlungsmethode automatisch in Rechnung zu stellen, wenn eine Mitgliedschaft damit verbunden ist.",    
                "onlineTransfer-SOFORTUEBERWEISUNG": "Ich autorisiere ALPrelax, diese Zahlungsmethode zu speichern sowie die Zahlungsmethode automatisch in Rechnung zu stellen, wenn eine Mitgliedschaft damit verbunden ist. Wiederkehrende Zahlungen erfolgen per Lastschrift."
            }

            function ww_addHeader(element, wrapper) {
                const header = document.createElement('div');
                header.classList.add('paymethod-header', 'ww-collapse-header');
                const icon = document.createElement('i');
                icon.classList.add('svg-icon', 'check');
                icon.innerHTML = '<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_272_287)"><path d="M1.36353 6.9334C2.49534 8.37207 3.6408 9.82807 4.77262 11.2667C7.73171 8.09473 10.6772 4.9054 13.6363 1.7334" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/></g><defs><clipPath id="clip0_272_287"><rect width="15" height="13" fill="white"/></clipPath></defs></svg>';
                header.appendChild(icon);
                const headline = document.createElement('h4');
                headline.classList.add('paymethod-headerline');
                headline.innerHTML = methodMapping[id.substring(0, id.lastIndexOf("_"))];
                header.appendChild(headline);
                wrapper.insertBefore(header, wrapper.firstElementChild);
                const brands = document.createElement('div');
                header.appendChild(brands);
                brands.className = 'header-brands';
                const brandSelect = element.querySelector('.wpwl-control-brand');
                if (brandSelect) {
                    brandSelect.querySelectorAll('option').forEach(option => {
                        const d = document.createElement('div');
                        d.className = 'wpwl-brand wpwl-brand-' + option.value;
                        brands.appendChild(d);
                    });
                } else {
                    element.querySelectorAll('.wpwl-brand').forEach(brand => brands.appendChild(brand.cloneNode()));
                }
            }

            function ww_addPaymentAdvice(element, wrapper) {
                const advice = document.createElement('div');
                advice.classList.add('ww-payment-advice');
                advice.innerHTML = wpwlPaymentAdvice[wrapper.dataset['paymentMethod']];
                element.insertBefore(advice, element.firstElementChild);
            }

            function ww_addCollapse(wrapper) {
                wrapper.addEventListener('transitionend', () => wrapper.style.height = '');
                const container = wrapper.parentNode;
                const header = wrapper.querySelector('.ww-collapse-header');
                header.addEventListener('click', ev => {
                    const active = container.querySelector('.is-expanded');
                    if (active && active.id != wrapper.id) {
                        const wpwl = active.querySelector('.wpwl-container');
                        if (wpwl) {
                            wpwl.style.height = 0;
                        }
                        active.classList.remove('is-expanded');
                        active.classList.add('is-collapsed');
                    }
                    if (wrapper.classList.contains('is-collapsed')) {
                        const wpwl = wrapper.querySelector('.wpwl-container');
                        if (wpwl) {
                            let h = 0;
                            wpwl.querySelectorAll(':scope > *:not(iframe)').forEach(el => {
                                h += el.getBoundingClientRect().height
                            });
                            wpwl.style.height = h + 'px';
                        }
                        wrapper.classList.add('is-expanded');
                        wrapper.classList.remove('is-collapsed');
                    }
                });
            }

            var methodMapping = {
                "card": " Debit- oder Kreditkarte",
                "onlineTransfer-EPS": " Sofortüberweisung",
                "virtualAccount-PAYPAL": " PayPal",
                "directDebit": " Click to pay with direct debit",
                "prepayment-BOLETO": " Click to pay with Boleto",
                "prepayment-BARPAY": " Click to pay with Barpay",
                "onlineTransfer-IDEAL": " Click to pay with iDeal",
                "onlineTransfer-GIROPAY": " Click to pay with GiroPay",
                "invoice-INVOICE": " Click to pay with Invoice",
                "onlineTransfer-SOFORTUEBERWEISUNG": " SOFORT Überweisung",
                "virtualAccount-PASTEANDPAY_V": " Click to pay with PASTEandPAY",
                "virtualAccount-VSTATION_V": " Click to pay with voucherstation",
                "virtualAccount-UKASH": " Click to pay with Ukash",
                "virtualAccount-QOOQO": " Click to pay with QOOQO",
                "virtualAccount-KLARNA_INVOICE": " Click to pay with Klarna Invoice",
                "virtualAccount-KLARNA_INSTALLMENTS": " Click to pay with Klarna Installments",
                "cashOnDelivery": " Click to pay cash on delivery"
            }
            var wpwlOptions = {
                locale: "de",
                onReady: function() {
                    document.querySelectorAll('.wpwl-container').forEach((el, idx) => {
                        id = el.id;
                        const wrapper = ww_wrapElement(el);
                        ww_addHeader(el, wrapper);
                        ww_addPaymentAdvice(el, wrapper);
                        ww_addCollapse(wrapper);
                        wrapper.classList.add('ww-collapse');
                        if (idx != 0) wrapper.classList.add('is-collapsed')
                        else wrapper.classList.add('is-expanded');
                    });              
                }  
            };
        `;

        document.body.appendChild(s)
            //pamyentscript
        s = document.getElementById('paymentScript');
            //remove any existing script
        if (s) {
            s.remove();
        }
        s = document.createElement('script');
        s.id = 'paymentScript';
        s.src = this.scriptUrl; //'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=' + this.checkoutId;
        document.body.appendChild(s);
    },


    /* async manageAbo(aboId : any) {
        const postData : any = `tx_wwalprelax_alprelax[abo]=${aboId}`
        const res : any = await AboRepository.manageAbo(postData);
        return res
    }, */

    checkPaymentResult() {
        const regSuccess = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/
        const regReviewSuccess = /^(000.400.0[^3]|000.400.100)/
        this.payment.successfully = this?.payment.statusResult?.result?.code.search(regSuccess) != -1
        const regPending = /^(000\.200)/
        this.payment.pending = this?.payment.statusResult?.result?.code.search(regPending) != -1
    }
}
export default PaymentController;