
import { defineComponent } from 'vue';

export default defineComponent({
    name: "DurationSetter",
    data(){
        return {
            filteredDuration: 0,
            all: true,
            lessTen: false,
            tenToFifteen: false,
            moreFifteen: false
        }
    },
    methods:{
        setFilteredDuration(event:Event, value:number){
            event.preventDefault();
            this.resetFilter();
            switch(value){
                case 0:
                    this.all = true;
                    break
                case 1:
                    this.lessTen = true;
                    break
                case 2:
                    this.tenToFifteen = true;
                    break
                case 3: 
                    this.moreFifteen = true;
                    break   
            }
            this.filteredDuration = value;
            this.handleSetDuration()
        },
        resetFilter(){
            this.all = false;
            this.lessTen = false;
            this.tenToFifteen = false;
            this.moreFifteen = false;
        },
        handleSetDuration(){
            this.$emit('handleSetDurationFilter', this.filteredDuration)
        },
    }
})
