
import { defineComponent } from 'vue';
import TimeSlider from '../TimeSlider/TimeSlider.vue';
import { MedSessionsController } from '@/controller';

export default defineComponent({
    name: "PlayContainer",
    components: {TimeSlider},
    props: {
        title: {
            type: String, 
            default: ""
        },
        medSessionFile: {
            type: String, 
            default: ""
        },
        favoritId: {
            type: Number,
            default: 0
        },
        statistic: {
            type: Object,
            default: null
        }
    },
    data(){
        return{
            statisticData: {
                uid: 0,
                pausedBy: 0,
                startedAt: 0
            },
            paused: true,
            duration: 0,
            currentTime: 0,
            favorit: false,
            icons:{
                fifteenback: '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="285.4px" height="308.7px" viewBox="0 0 285.4 308.7" enable-background="new 0 0 285.4 308.7" xml:space="preserve"><g><polygon fill="#143D63" points="107.6,162.7 110.8,169.4 119.1,165.9 119.1,232.2 127,232.2 127,157.1 121.1,157.1 "/><path fill="#143D63" d="M160.5,184.2c-4.2,0-8.1,1.1-11.6,2.9l1.1-22.5h30.2V157h-37.6l-1.7,38.7l6.4,1.2l0.4-0.4c3.5-2.7,6.7-4.9,12.8-4.9c9.4,0,15.8,6.9,15.8,17.1c0,8.6-4.6,17.2-15.1,17.2c-8.3,0-13.7-3.6-16.1-10.9l-0.4-1.3l-6.9,2.9l0.4,1.2c1.2,3.7,6.3,15.8,23.2,15.8c13.6,0,22.9-10.2,22.9-24.7C184.3,194.1,174.7,184.2,160.5,184.2z"/></g><g><path fill="#163D64" d="M279,182.9c-1.2-32.6-15.1-63.2-39-86.2C216,73.7,184.9,61,152.3,61l-93.7,0l-9.6,8.6L59.8,77l93.1,0c28.3,0,55.4,11,76.2,31c20.8,20,32.8,46.6,33.9,74.9c2.2,58.4-43.6,106-102,106l-69.3,0c-4.4,0-7.9,3.6-7.7,8c0.2,4.4,3.9,8,8.3,8l69.3,0C228.9,304.9,281.5,250.2,279,182.9z"/><path fill="#163D64" d="M48.6,61l10,0L107.7,17c3.2-2.9,3.4-7.9,0.4-11.3C104.9,2.4,100,2.1,96.7,5L23,71l79,53.2c3.7,2.5,8.7,1.6,11-2c2.4-3.6,1.3-8.6-2.4-11.1L59.8,77l-11.2,0C48.6,71.8,48.6,66.3,48.6,61z"/><path fill="#163D64" d="M58.6,61l-10,0c0,5.3,0,10.8,0,16l11.2,0l-10.9-7.3L58.6,61z"/></g></svg>',
                arrow: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" enable-background="new 0 0 500 500" xml:space="preserve"><g><g><line fill="none" stroke="#163D64" stroke-width="18.4169" stroke-miterlimit="184.1689" x1="0" y1="250" x2="500" y2="250"/></g><g><path fill="none" stroke="#163D64" stroke-width="18.4169" stroke-miterlimit="184.1689" d="M175,429.6c0-96.6-78.3-175-175-175"/><path fill="none" stroke="#163D64" stroke-width="18.4169" stroke-miterlimit="184.1689" d="M0,245.4c96.6,0,175-78.3,175-175"/></g></g></svg>',
                play: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 697.9 697.9" enable-background="new 0 0 697.9 697.9" xml:space="preserve"><g><path fill="#163D64" d="M670.6,213.1c-17.6-41.6-42.7-78.9-74.8-110.9S526.4,45,484.9,27.4C441.8,9.2,396.1,0,349,0s-92.8,9.2-135.9,27.4c-41.6,17.6-78.9,42.7-110.9,74.8S45,171.6,27.4,213.1C9.2,256.2,0,301.9,0,349s9.2,92.8,27.4,135.9c17.6,41.6,42.7,78.9,74.8,110.9s69.4,57.2,110.9,74.8c43,18.2,88.8,27.4,135.9,27.4s92.8-9.2,135.9-27.4c41.6-17.6,78.9-42.7,110.9-74.8s57.2-69.4,74.8-110.9c18.2-43,27.4-88.8,27.4-135.9S688.8,256.2,670.6,213.1z M349,638C189.6,638,60,508.4,60,349S189.6,60,349,60s289,129.6,289,289S508.4,638,349,638z"/><path fill="#163D64" d="M495.5,327.7L271.1,198.2c-16.4-9.4-36.8,2.4-36.8,21.3v259.1c0,18.9,20.5,30.7,36.8,21.3l224.4-129.5C511.8,360.8,511.8,337.2,495.5,327.7z"/></g></svg>',
                pause: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 698 698" enable-background="new 0 0 698 698" xml:space="preserve"><g><g id="Ellipse_1"><circle fill="none" stroke="#163D64" stroke-width="60" cx="349" cy="349" r="319"/></g><g id="Gruppe_18" transform="translate(25.357 20.237)"><path id="Rechteck_27" fill="#163D64" d="M382.5,181.5L382.5,181.5c16.3,0,29.4,13.2,29.4,29.4v245.4c0,16.3-13.2,29.4-29.4,29.4l0,0c-16.3,0-29.4-13.2-29.4-29.4V211C353,194.7,366.2,181.5,382.5,181.5z"/><path id="Rechteck_28" fill="#163D64" d="M264.7,181.5L264.7,181.5c16.3,0,29.4,13.2,29.4,29.4v245.4c0,16.3-13.2,29.4-29.4,29.4l0,0c-16.3,0-29.4-13.2-29.4-29.4V211C235.3,194.7,248.4,181.5,264.7,181.5z"/></g></g></svg>',
                heart_outline: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve"><g id="heart-svgrepo-com" transform="translate(0 -28.501)"><path id="Pfad_45" fill="none" stroke="#183E64" stroke-width="20" stroke-miterlimit="10" d="M252.2,91.1c-26.4-26.3-69.2-26.3-95.5,0l-6.7,6.7l-6.7-6.7C117,64.7,74.3,64.6,48,90.9c0,0,0,0-0.1,0.1c-26.3,26.3-26.3,69.1,0,95.4c0,0,0,0,0.1,0.1l97.2,97.2c2.7,2.7,7,2.7,9.8,0.1l0,0l97.4-97C278.4,160.3,278.4,117.6,252.2,91.1L252.2,91.1z"/></g></svg>',
                heart: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1"xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve"><g id="heart-svgrepo-com" transform="translate(0 -28.501)"><path id="Pfad_45" fill="#173E64" stroke="#183E64" stroke-width="20" stroke-miterlimit="10" d="M252.2,91.1c-26.4-26.3-69.2-26.3-95.5,0l-6.7,6.7l-6.7-6.7C117,64.7,74.3,64.6,48,90.9c0,0,0,0-0.1,0.1c-26.3,26.3-26.3,69.1,0,95.4c0,0,0,0,0.1,0.1l97.2,97.2c2.7,2.7,7,2.7,9.8,0.1l0,0l97.4-97C278.4,160.3,278.4,117.6,252.2,91.1L252.2,91.1z"/></g></svg>',
            },
            //playPressed because iPhone 13 =< doesn't support @loadeddata
            playPressed: false
        }
    },
    created() {
        //console.log('PlayContainer:created:statistic', this.statistic);
        if (this.statistic) this.currentTime = this.statistic.pausedBy
    },
    computed: {
        isFavorit(){
            if(this.favoritId > 0 ){
                return true
            } else {
                return false
            }
        }
    },
    beforeUnmount() {
        MedSessionsController.isDirty = true
        this.handleStopAudio()
    },
    methods: {
        convertToMinutes(time:number){
            const result = `${Math.floor(time / 60).toString().padStart(2, '0')}:${Math.round(time % 60).toString().padStart(2, '0')}`;
            return result
        },
        handleLoadedAudio(){
            //console.log('audio loaded')
            const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement
            this.duration = audio.duration
            if(this.statistic.uid && this.statistic.uid >0){
                if(this.statistic.startedAt && Date.now()- (parseInt(this.statistic.startedAt))*1000<60*60*24){
                    audio.currentTime = this.statistic.pausedBy
                    this.currentTime = this.statistic.pausedBy
                }
            } else {
                audio.currentTime = 0
                this.currentTime = 0
            }
            //playPressed because iPhone 13 =< doesn't support @loadeddata
            if(this.playPressed){
                audio.play()
            } else {
                audio.pause()
            }
        },  
        onTimeUpdateListener(){
           const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement
            this.currentTime = audio.currentTime 
        },
        /**
         * play audio on iphone: https://codepen.io/kslstn/pen/pagLqL
         * 
         */        
        handlePlayAudio(event:Event){
            event.preventDefault()
            //console.log('play audio')
            
            const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement
            const ct = this.currentTime
            audio.play();
            this.playPressed = true;
            this.paused = false
            this.$emit('onPlay', ct);
            const navigator : any = window.navigator;

            //console.log('window', window);

            if ("mediaSession" in navigator) {
                navigator.mediaSession.metadata = new (window as any).MediaMetadata({
                    title: this.title,
                    artist: "ALPrelax - deine Wohlfühlapp",
                    artwork: [
                    {
                        src: "/img/icons/android-icon-96x96.png",
                        sizes: "96x96",
                        type: "image/png",
                    },
                    {
                        src: "/img/icons/apple-icon-144x144.png",
                        sizes: "144x144",
                        type: "image/png",
                    },
                    {
                        src: "/img/icons/android-icon-192x192.png",
                        sizes: "192x192",
                        type: "image/png",
                    },
                    {
                        src: "/img/icons/apple-icon-512x512.png",
                        sizes: "512x512",
                        type: "image/png",
                    },
                    ],
                });

                navigator.mediaSession.setActionHandler('pause', () => {
                    audio.pause();
                });
                navigator.mediaSession.setActionHandler('play', () => {
                    audio.play()
                });
                navigator.mediaSession.setActionHandler('seekbackward', (details : any) => {
                    audio.currentTime = audio.currentTime - (details.seekOffset || 10);
                });
                navigator.mediaSession.setActionHandler('seekforward', (details : any) => {
                    audio.currentTime = audio.currentTime + (details.seekOffset || 10);
                });                
                //console.log('window', navigator.mediaSession);
            }
        },
        stopAudioClick(event:Event){
            event.preventDefault()
            this.handleStopAudio()
        },
        handleStopAudio(){
            this.playPressed = false;

            //console.log('stop Audio')
            const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement            
            audio.pause()
            this.setAudioPaused()
        },
        onAudioPause() {
            this.playPressed = false
            this.setAudioPaused()
        },
        handleFifteenSecondsBack(event: Event){
            event.preventDefault()
            const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement
            audio.currentTime = this.currentTime -15
        },
        handleSetCurrentTime(time: any){
            const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement
            audio.currentTime = time
        },
        handleUpdateStatistic(){
            this.$emit('updateStatistic', this.statisticData)
        },
        handleToggleFavorite(){
            this.$emit('handleToggleFavorite', !this.isFavorit)
        },
        setAudioPaused() {
            const audio : HTMLAudioElement = this.$refs.medplayer as HTMLAudioElement
            this.paused = true
            this.statisticData.pausedBy = audio.currentTime
            this.statisticData.uid = this.statistic.uid
            this.handleUpdateStatistic()
            this.$emit('onPause')
        }
    }
})
