export const Api = Object.freeze({
    BACKEND_URL: process.env.VUE_APP_BACKEND,

    //CATEGORY
    GETCATEGORIES_ACTION: 'category/?tx_wwalprelax_category[action]=list',

    //LOGIN
    CHECK_LOGIN_ACTION: 'login/',
    LOGIN_ACTION:'login/?tx_wwalprelax_login[action]=login',
    LOGOUT_ACTION:'login/?tx_wwalprelax_login[action]=logout',

    //USER
    CREATEUSER_ACTION: 'user/?tx_wwalprelax_user[action]=createUser',
    EDIT_USER_ACTION: 'user/?tx_wwalprelax_user[action]=updateUser',
    CHANGEPASSWORD_ACTION: 'user/?tx_wwalprelax_user[action]=changePassword',
    CHECK_EMAIL_ACTION: "user/?tx_wwalprelax_user[action]=confirmemail",
    GET_USER_ACTION: 'user/?tx_wwalprelax_user[action]=getUser',
    DELETE_ACCOUNT_ACTION:'user/?tx_wwalprelax_user[action]=deleteUser',
    SENDPASSWORDLINK_ACTION: 'user/?tx_wwalprelax_user[action]=sendForgotLink',
    RESET_PASSWORD_ACTION: 'user/?tx_wwalprelax_user[action]=resetPassword',
    COUNTRIES_ACTION: 'user/?tx_wwalprelax_user[action]=countries',
    UPDATE_BILLING_ACTION: 'user/?tx_wwalprelax_user[action]=updateBilling',

    //SESSION
    GETMEDSESSIONS_ACTION: 'session/?tx_wwalprelax_session[action]=list',
    GETMEDSESSION_ACTION: 'session/?tx_wwalprelax_session[action]=get',
    GET_MEDSESSION_FILE_ACTION: 'session/?tx_wwalprelax_session[action]=getFile',

    //Favorits
    ADD_FAVORIT_ACTION: 'favorit/?tx_wwalprelax_favorit[action]=create',
    REMOVE_FAVORIT_ACTION: 'favorit/?tx_wwalprelax_favorit[action]=remove',
    GETFAVORITES_ACTION: '',

    //STATISTIC
    CREATE_STATISTIC_ACTION: 'statistic/?tx_wwalprelax_statistic[action]=create',
    UPDATE_STATISTIC_ACTION: 'statistic/?tx_wwalprelax_statistic[action]=update',

    //PLAYSTATISTIC
    CREATE_PLAYSTATISTIC_ACTION: 'playstatistic/?tx_wwalprelax_playstatistic[action]=create',

    //ABO
    GETABOS_ACTION: 'abo/?tx_wwalprelax_abo[action]=list',
    MANAGE_ABO_ACTION:'abo/?tx_wwalprelax_abo[action]=manage',
    CHANGE_ABO_ACTION:'abo/?tx_wwalprelax_abo[action]=change',
    REDO_CHANGE_ABO_ACTION:'abo/?tx_wwalprelax_abo[action]=redoChange',
    CANCEL_ABO_ACTION:'abo/?tx_wwalprelax_abo[action]=cancel',
    REDO_CANCEL_ABO_ACTION:'abo/?tx_wwalprelax_abo[action]=redoCancel',
    GETHIDDENABO_ACTION: 'abo/?tx_wwalprelax_abo[action]=get',
    JOIN_COMPANY_ACTION:'abo/?tx_wwalprelax_abo[action]=joinCompany',

    //Payment
    PAYMENT_DATA_ACTION: 'payment/?tx_wwalprelax_payment[action]=data',
    PAYMENT_PREPARE_ACTION: 'payment/?tx_wwalprelax_payment[action]=prepare',
    PAYMENT_CHECK_PAYMENT_ACTION: 'payment/?tx_wwalprelax_payment[action]=check',
    PAYMENTS_ACTION: 'payment/?tx_wwalprelax_payment[action]=payments',

    //FAQ
    GET_FAQS_ACTION: 'faq/?tx_wwalprelax_alprelax[action]=list',

    //NEWSLETTER
    SUBSCRIBE_NEWSLETTER_ACTION: 'newsletter/?tx_wwalprelax_newsletter[action]=subscribe',
    UNSUBSCRIBE_NEWSLETTER_ACTION: 'newsletter/?tx_wwalprelax_newsletter[action]=unsubscribe',

    //INVOICE
    DOWNLOAD_INVOICE_ACTION: 'invoice/?tx_wwalprelax_invoice[action]=download',

    //CONTENT
    CONTENT_URI: 'content/',

    //APPLE
    APPLE_PURCHASE_TOKEN_CREATE: 'applepurchase/?tx_wwalprelax_applepurchase[action]=create',
})

