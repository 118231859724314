import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 1,
  class: "footer-container",
  ref: "domFixedFooter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FixedHeaderTop = _resolveComponent("FixedHeaderTop")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NavBottom = _resolveComponent("NavBottom")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.viewClass)
  }, [
    (_ctx.showFixedHeader)
      ? (_openBlock(), _createElementBlock("header", {
          key: 0,
          ref: "domFixedHeader",
          class: _normalizeClass(["fixed-header", 'view-' + _ctx.routeName])
        }, [
          _createVNode(_component_FixedHeaderTop)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      class: "appmain ar-scrollbar",
      style: _normalizeStyle('height: ' + _ctx.appmainHeight  + 'px;'),
      ref: "domAppmain"
    }, [
      (_ctx.showPageHeader)
        ? (_openBlock(), _createBlock(_component_PageHeader, {
            key: 0,
            routeName: _ctx.routeName
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", {
                class: "page-header-text",
                innerHTML: _ctx.getfPageHeaderContent
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["routeName"]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }))
    ], 4),
    ((_ctx.showFooter && !_ctx.store.getters.getHideNavbar))
      ? (_openBlock(), _createElementBlock("footer", _hoisted_2, [
          _createVNode(_component_NavBottom, { activeView: _ctx.key }, null, 8, ["activeView"])
        ], 512))
      : _createCommentVNode("", true)
  ], 2))
}