
import { ApplePurchaseRepository } from "../repository";

const ApplePurchaseController = {
    async createToken( token : any){
        const postData : any = `tx_wwalprelax_applepurchase[token]=${token}`
        const res : any = await ApplePurchaseRepository.createToken(postData);
        return res
    }
}
export default ApplePurchaseController;