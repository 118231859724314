
import { StatisticRepository } from "../repository";
import { MedSessionsController } from "./index";

const StatisticController = {
    async createStatistic(sessionId:any){
        //Quik and dirty, da Date.now() eine zu lange Zahl erzeugt??
        const now = Date.now().toString().slice(0, -3)
        const postData =  `tx_wwalprelax_statistic[startedAt]=${now}&tx_wwalprelax_statistic[session]=${sessionId}`;
        const res : any = await StatisticRepository.createStatistic(postData);
        return res
    },
    async updateStatistic(updateData:any, sessionId:any){
        //Quik and dirty, da Date.now() eine zu lange Zahl erzeugt??
        MedSessionsController.isDirty = true
        const now = Date.now().toString().slice(0, -3)
        const postData = `tx_wwalprelax_statistic[userStatistic][__identity]=${updateData.uid}&tx_wwalprelax_statistic[userStatistic][pausedBy]=${updateData.pausedBy}&tx_wwalprelax_statistic[userStatistic][medsession]=${sessionId}&tx_wwalprelax_statistic[userStatistic][startedAt]=${now}`;
        const res : any = await StatisticRepository.updateStatistic(postData);
        MedSessionsController.medSessions = MedSessionsController.medSessions.map((item:any) =>{
            if(item.uid == sessionId){
                item.statistic = res.data.data
                item.available = res.data.data.available
            }
            return item
        })
        MedSessionsController.isDirty = false
        return res
    }
}
export default StatisticController;