import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider-bar" }
const _hoisted_2 = { class: "time-preview" }
const _hoisted_3 = { class: "current-time" }
const _hoisted_4 = { class: "remaining-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "slider-container",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
      onMousemove: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseMove && _ctx.onMouseMove(...args))),
      onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
      onTouchmove: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onTouchMove && _ctx.onTouchMove(...args))),
      onMouseup: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args))),
      onTouchend: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onTouchEnd && _ctx.onTouchEnd(...args))),
      ref: "domSliderContainer"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(["slider-progress", { 'is-dragging': _ctx.dragging }]),
          style: _normalizeStyle(`width: calc(${_ctx.progress}% + 8px);`)
        }, null, 6),
        _createElementVNode("span", {
          onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
          onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTouchStart && _ctx.onTouchStart(...args))),
          class: _normalizeClass(["slider-thumb", { 'is-dragging': _ctx.dragging }]),
          ref: "thumb",
          style: _normalizeStyle(`left: ${_ctx.progress}%`)
        }, null, 38)
      ])
    ], 544),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.convertToMinutes(_ctx.currentTime)), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.convertToMinutes(_ctx.remainingTime)), 1)
    ])
  ]))
}