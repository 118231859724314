import axios from "axios";
import { Api } from "../api";

const InfoRepository = {
 /**
     * getFAQs
     * 
     * 
     * @returns {Object} server response
     */  
    async getFAQs(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_FAQS_ACTION );
            console.log('getFAQs res', res)
            return res
        } catch (e){
            console.log('getFAQs error',e);
            return {};
        }
   }
}

export default InfoRepository;