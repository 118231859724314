import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "ww-popup-container",
  class: "ww-popup-container"
}
const _hoisted_2 = { class: "ww-popup-inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "ww-popup-content" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "ww-popup-close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
      }, [
        _createElementVNode("i", {
          class: "svg-icon",
          innerHTML: _ctx.icon.close
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.displayContent == '')
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "svg-icon svg-icon-loader",
              innerHTML: _ctx.icon.loader
            }, null, 8, _hoisted_5))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              innerHTML: _ctx.displayContent
            }, null, 8, _hoisted_6))
      ])
    ])
  ]))
}