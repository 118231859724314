
import { ContentRepository } from "../repository";
import { Api } from "../api";

const ContentController = {
    ABOUT_URI: 'ueber-sophie' as String,
    PRIVACY_URI: 'datenschutz' as String,
    TERMS_URI: 'agb' as String,

    async getContent(uri: String) {
        const res : any = await ContentRepository.getContent(uri)
        console.log('contentController:getContent', res);
        if (res.data) {
            const d = document.createElement('div')
            d.innerHTML = res.data
            const main = d.querySelector('main');
            const html = main?.innerHTML || ''
            return ContentController.replaceImageSrc(html)
        } else {
            return res.error
        }
        
        //return res
    },

    replaceImageSrc(content: string) {
        const el = document.createElement('div')
        el.innerHTML = content;
        el.querySelectorAll('img').forEach(img => {
            const src = img.src.substr(img.src.indexOf('fileadmin'))
            img.src = Api.BACKEND_URL + src
        })
        return el.innerHTML;
    }
       
}
export default ContentController;