
import { defineComponent } from "vue"

export default defineComponent({
    name: "PageHeader",
    data() {
        return{
            logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
        }
    },
    computed: {
        headerClass() {
            return this.defaultHeader ? 'default-header' : 'img-header' + ' ' + this.routeName
        },
    },
    props: {
       defaultHeader: {
        type: Boolean,
        default: false
       },
       routeName: {
        type: String,
        default: ''
       }
    },
    methods: {
        handleHomeClick(ev: Event) {
            this.$router.push("/")
            ev.preventDefault();
        },
    }
})
