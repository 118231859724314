import axios from "axios";
import { Api } from "../api";

const StatisticRepository = {
     /**
     *createStatistic
     *    
     * 
     * @param {Object} sessionID
     * 
     * @returns {Object} server response
     */ 
     async createStatistic(sessionID : any) {
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.CREATE_STATISTIC_ACTION, sessionID);
            //console.log('createStatistic Response', res)

            return res;
        } catch(e){
            console.log('createStatistic error:', e);
            return {}
        }
    },   
     /**
     * updateStatistic
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */ 
      async updateStatistic(postData : any) {
        try{
            const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_STATISTIC_ACTION, postData);
            //console.log('updateStatistic Response', res)

            return res;
        } catch(e){
            console.log('updateStatistic error:', e);
            return {}
        }
    },    
}
export default StatisticRepository;