import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "ww-page-overlay-loader",
  class: "ww-page-overlay-loader showAlert"
}
const _hoisted_2 = { class: "ww-page-overlay-loader-content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "loader-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: "svg-icon",
        innerHTML: _ctx.loader
      }, null, 8, _hoisted_3),
      (_ctx.loadertext)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.loadertext), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}