import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/views/layout/index.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        component: () => import(/* webpackChunkName: "home" */  "@/views/home/index.vue"),
        name: "home",
        meta: {
          title: "Start: ALPrelax",
          noCache: true,
          showFooter: true,
          showPageHeader: true,
          showFixedHeader: false
        }
      }
    ]
  },
  {
    path: "/confirmemail",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/confirmemail/:id",
        component: () => import("@/views/confirmemail/index.vue"),
        name: "confirmemail",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false        
        }
      }
    ]
  },    
  {
    path: "/login",
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/index.vue"),
        name: "login",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false              
        }
      }
    ]
  },
  {
    path: "/resetpassword",
    hidden: true,
    children: [
      {
        path: "/resetpassword/:hash",
        component: () => import("@/views/resetpassword/index.vue"),
        name: "resetpassword",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false          
        }
      }
    ]
  },
  {
    path: "/manageabo",
    component: Layout,
    children: [
      {
        path: "/manageabo/:abocode?",
        component: () => import("@/views/manageabo/index.vue"),
        name: "manageabo",
        meta: {
          showFooter: true,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderMaxWidth: 48,
          fixedHeaderBackButton: true
        }
      }
    ]
  },
  {
    path: "/payment",
    component: Layout,
    children: [
      {
        path: "/payment",
        component: () => import("@/views/payment/index.vue"),
        name: "payment",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false
        }        
      }
    ]
  },    
  {
    path: "/paymentstatus",
    component: Layout,
    children: [
      {
        path: "/paymentstatus",
        component: () => import("@/views/paymentstatus/index.vue"),
        name: "paymentstatus",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false
        }          
      }
    ]
  },
  {
    path: "/about",
    component: Layout,
    children: [
      {
        path: "/about",
        component: () => import("@/views/about/index.vue"),
        name: "about",
        meta: {
          showFooter: true,
          showPageHeader: true,
          showFixedHeader: false,
          fixedHeaderLogo: false
        }
      }
    ]
  },  
  {
    path: "/privacy",
    component: Layout,
    children: [
      {
        path: "/privacy",
        component: () => import("@/views/privacy/index.vue"),
        name: "privacy",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderBackButton: true,
          fixedHeaderCloseButton: false
        }         
      }
    ]
  },
  {
    path: "/medsession",
    component: Layout,
    children: [
      {
        path: "/medsession/:id",
        component: () => import("@/views/medsession/index.vue"),
        name: "medsession",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderLogo: true,
          fixedHeaderBackButton: true,
        }    
      }
    ]
  },  
  {
    path: "/account",
    component: Layout,
    children: [
      {
        path: "/account",
        component: () => import("@/views/account/index.vue"),
        name: "account",
        meta: {
          showFooter: true,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderLogo: false,
          fixedHeaderLogoutButton: true,
          fixedHeaderBackButton: false,
        }         
      }
    ]
  },
  {
    path: "/meditations",
    component: Layout,
    children: [
      {
        path: "/meditations",
        component: () => import("@/views/meditations/index.vue"),
        name: "meditations",
        meta: {
          showFooter: true,
          showPageHeader: true,
          showFixedHeader: false,
          fixedHeaderLogo: false
        }         
      }
    ]
  },
  {
    path: "/favorits",
    component: Layout,
    children: [
      {
        path: "/favorits",
        component: () => import("@/views/favorits/index.vue"),
        name: "favorits",
        meta: {
          title: "Alprelax",
          noCache: true,
          showFooter: true,
          showPageHeader: true,
          showFixedHeader: false,
          fixedHeaderLogo: false    
        }
      }
    ]
  },
  {
    path: "/edituser",
    component: Layout,
    children: [
      {
        path: "/edituser",
        component: () => import("@/views/edituser/index.vue"),
        name: "edituser",
        meta: {
          showFooter: true,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderLogo: false,
          fixedHeaderBackButton: true
        }            
      }
    ]
  },
  {
    path: "/changepassword",
    component: Layout,
    children: [
      {
        path: "/changepassword",
        component: () => import("@/views/changepassword/index.vue"),
        name: "changepassword",
        meta: {
          showFooter: true,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderLogo: false,
          fixedHeaderBackButton: true
        }            
      }
    ]
  },
  {
    path: "/willkommen",
    component: Layout,
    children: [
      {
        path: "/willkommen/:id?",
        component: () => import("@/views/registerform/index.vue"),
        name: "willkommen",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false,
          fixedHeaderMaxWidth: 48
        }           
      }
    ]
  },
  {
    path: "/promotion",
    component: Layout,
    children: [
      {
        path: "/promotion/:id?",
        component: () => import("@/views/registerform/index.vue"),
        name: "promotion",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false,
          fixedHeaderMaxWidth: 48
        }           
      }
    ]
  },  
  {
    path: "/wohlfuehlen",
    component: Layout,
    children: [
      {
        path: "/wohlfuehlen/:code?",
        component: () => import("@/views/registerform/index.vue"),
        name: "wohlfuehlen",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false,
          fixedHeaderMaxWidth: 48
        }           
      }
    ]
  },   
  {
    path: "/registerform",
    component: Layout,
    children: [
      {
        path: "/registerform/:id?",
        component: () => import("@/views/registerform/index.vue"),
        name: "registerform",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: false,
          fixedHeaderMaxWidth: 48
        }           
      }
    ]
  },
  {
    path: "/loginform",
    component: Layout,
    children: [
      {
        path: "/loginform",
        component: () => import("@/views/loginform/index.vue"),
        name: "loginform",
      }
    ]
  },
  {
    path: "/forgotpassword",
    component: Layout,
    children: [
      {
        path: "/forgotpassword",
        component: () => import("@/views/forgotpassword/index.vue"),
        name: "forgotpassword",
      }
    ]
  },
  {
    path: "/faqs",
    component: Layout,
    children: [
      {
        path: "/faqs",
        component: () => import("@/views/faqs/index.vue"),
        name: "faqs",
        meta: {
          showFooter: false,
          showPageHeader: false,
          showFixedHeader: true,
          fixedHeaderLogo: false,
          fixedHeaderBackButton: true
        }          
      }
    ]
  },
  {
    path: "/a2hs",
    component: Layout,
    children: [
      {
        path: "/a2hs",
        component: () => import("@/views/a2hs/index.vue"),
        name: "a2hs",
      }
    ]
  }  
  /*{
    path: "/pagenotfound",
    children: [
        {
            path: "/pagenotfound",
            component: () => import("@/views/pagenotfound/index.vue"),
            name: "pagenotfound",
            meta: {
              title: "Alprelax: Page not found.",
              noCache: true
            }
          }
    ]
  },*/
];

const router = createRouter({
    history: createWebHistory(""),
    routes
});

export default router;