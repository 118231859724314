
import { defineComponent } from 'vue';

export default defineComponent({
    name: "TimeSlider",
    props: {
        currentTime: {
            type: Number, 
            default: 0
        },
        duration: {
            type: Number, 
            default: 0
        }
    },
    data(){
        return{
            setTime: 0,
            dragging: false,
            containerWidth: 0,
        }
    },
    computed:{
        remainingTime(){
            return this.duration - this.currentTime
        },
        progress(){
            //return Math.round(this.currentTime / this.duration *100)
            return this.currentTime / this.duration *100
        },
    },
    methods: {
        convertToMinutes(time:number){
            const result = `${Math.floor(time / 60).toString().padStart(2, '0')}:${Math.round(time % 60).toString().padStart(2, '0')}`;
            return result
        },
        onClick(e:MouseEvent) {
            //console.log('on click', this.dragging)
            e.preventDefault()  
            this.handleSetThumbPosition(e.clientX)
        },
        onTap(/* e:TouchEvent */) {
            //console.log('on tap', e)
        },
        onTouch(/* e:TouchEvent */) {
            //e.preventDefault()
            //console.log('onTouch')
        },
        handleSetThumbPosition(x:number){
            const delta = x-(this.$refs['domSliderContainer'] as any).getBoundingClientRect().left
            let factor = Math.round(delta*this.duration/(this.$refs['domSliderContainer'] as any).getBoundingClientRect().width)
            this.$emit('setCurrentTime', factor)
        },
        onMouseDown(e:MouseEvent) {
            e.preventDefault()
            this.startDragging()
        },
        onTouchStart(e:TouchEvent) {
            e.preventDefault()
            this.startDragging()
        },
        startDragging(){
            this.dragging = true;
        },
        onMouseMove(e:MouseEvent) {
            //e.preventDefault()
            if(this.dragging){
                this.sliderMove(e.clientX)
            }
        },
        sliderMove(x:number){
            //console.log('sliderMove:', x)
            const delta = x-(this.$refs['domSliderContainer'] as any).getBoundingClientRect().left
            let factor = Math.round(delta*this.duration/(this.$refs['domSliderContainer'] as any).getBoundingClientRect().width)
            this.$emit('setCurrentTime', factor)
        },
        onTouchMove(e:TouchEvent) {
            //e.preventDefault()
            if(this.dragging){
                this.sliderMove(e.touches[0].pageX)
            }
        },
        /* handleTouchMove(x:number){
            if(this.dragging){
                
                const delta = x-(this.$refs['domSliderContainer'] as any).getBoundingClientRect().left
                let factor = Math.round(delta*this.duration/(this.$refs['domSliderContainer'] as any).getBoundingClientRect().width)
                this.$emit('setCurrentTime', factor)
            }
        }, */
        onMouseLeave(/* e:MouseEvent */) {
            //e.preventDefault()
            this.stopDragging();
        },
        onMouseUp(/* e:MouseEvent */) {
            //e.preventDefault()
            this.stopDragging()
        },
        onTouchEnd(/* e:TouchEvent */) {
            //e.preventDefault()
            this.stopDragging()
            
        },
        stopDragging(){
            this.dragging = false
        },
    }
})
