import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "play-container"
}
const _hoisted_2 = { class: "play-container-content" }
const _hoisted_3 = { class: "player-actions" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["title"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeSlider = _resolveComponent("TimeSlider")!

  return (_ctx.medSessionFile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TimeSlider, {
            currentTime: _ctx.currentTime,
            duration: _ctx.duration,
            onSetCurrentTime: _ctx.handleSetCurrentTime
          }, null, 8, ["currentTime", "duration", "onSetCurrentTime"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFifteenSecondsBack && _ctx.handleFifteenSecondsBack(...args))),
              class: "transparent-button side-actions"
            }, [
              _createElementVNode("i", {
                innerHTML: _ctx.icons.fifteenback,
                class: "player-icon fifteenback"
              }, null, 8, _hoisted_4)
            ]),
            (_ctx.paused)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePlayAudio && _ctx.handlePlayAudio(...args))),
                  class: "transparent-button play-btn"
                }, [
                  _createElementVNode("i", {
                    innerHTML: _ctx.icons.play,
                    class: "player-icon play"
                  }, null, 8, _hoisted_5)
                ]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stopAudioClick && _ctx.stopAudioClick(...args))),
                  class: "transparent-button play-btn"
                }, [
                  _createElementVNode("i", {
                    innerHTML: _ctx.icons.pause,
                    class: "player-icon play"
                  }, null, 8, _hoisted_6)
                ])),
            (!_ctx.isFavorit)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleToggleFavorite && _ctx.handleToggleFavorite(...args))),
                  class: "transparent-button side-actions"
                }, [
                  _createElementVNode("i", {
                    innerHTML: _ctx.icons.heart_outline,
                    class: "player-icon heart"
                  }, null, 8, _hoisted_7)
                ]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleToggleFavorite && _ctx.handleToggleFavorite(...args))),
                  class: "transparent-button side-actions"
                }, [
                  _createElementVNode("i", {
                    innerHTML: _ctx.icons.heart,
                    class: "player-icon heart"
                  }, null, 8, _hoisted_8)
                ]))
          ]),
          _createElementVNode("audio", {
            title: _ctx.title,
            artist: "ALPrelax - deine Wohlfühlapp",
            class: "html-player",
            id: "medPlayer",
            ref: "medplayer",
            preload: "metadata",
            onLoadeddata: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleLoadedAudio && _ctx.handleLoadedAudio(...args))),
            onTimeupdate: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onTimeUpdateListener && _ctx.onTimeUpdateListener(...args))),
            onEnded: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.stopAudioClick && _ctx.stopAudioClick(...args))),
            onPause: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onAudioPause && _ctx.onAudioPause(...args)))
          }, [
            _createElementVNode("source", {
              src: _ctx.medSessionFile,
              type: "audio/mpeg"
            }, null, 8, _hoisted_10)
          ], 40, _hoisted_9)
        ])
      ]))
    : _createCommentVNode("", true)
}