import axios from "axios";
import { Api } from "../api";

const MedSessionsRepository = {
    /**
    *getMedSessions
    *
    * @returns {Object}
    */
    async getMedSessions(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GETMEDSESSIONS_ACTION);
            //console.log("'getMedSessions Response", res)
            return res

        } catch (e){
            console.log('getMedSessions error', e);
            return {};
        }
    },
    /**
    *getMedSession
    *
    * @returns {Object}
    */
    async getMedSession(postData:any){
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.GETMEDSESSION_ACTION, postData);
            //console.log("'getMedSession Response", res)
            return res

        } catch (e){
            console.log('getMedSession error', e);
            return {};
        }
    },
    /**
    *getFile
    *
    * @returns {Object}
    */
    async getFile(postData:any){
        try{
            const res = await axios.create().post(Api.BACKEND_URL + Api.GET_MEDSESSION_FILE_ACTION, postData);
            //console.log("'getFile Response", res)
            return res

        } catch (e){
            console.log('getFile error', e);
            return {};
        }
    }
}
export default MedSessionsRepository;