
import { FavoritRepository } from "../repository";

const FavoritController = {
    favorits: [] as Array<any>,

    async getFavorits(){
        const res : any = await FavoritRepository.getFavorits();
        if(res.data.success){
            this.favorits = res.data.data;
            return res
        } 
        return res;
    },

    async addFavorit( sessionId : any){
        const postData : any = `tx_wwalprelax_favorit[session]=${sessionId}`
        const res : any = await FavoritRepository.addFavorit(postData);
        return res
    },
    async removeFavorit(favoritId : any){
        const postData : any = `tx_wwalprelax_favorit[favoritId]=${favoritId}`
        const res : any = await FavoritRepository.removeFavorit(postData);
        return res
    }
}
export default FavoritController;