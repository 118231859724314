import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ww-password-input" }
const _hoisted_2 = ["type", "placeholder", "tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconInline = _resolveComponent("IconInline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.inputType,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
      spellcheck: "false",
      tabindex: _ctx.tabindex,
      autocomplete: "off"
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, _ctx.input]
    ]),
    _createVNode(_component_IconInline, {
      icon: _ctx.icon,
      raised: true,
      onHandleClicked: _ctx.handleToggleInputType,
      tabindex: "-1"
    }, null, 8, ["icon", "onHandleClicked"])
  ]))
}