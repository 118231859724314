
import { MedSessionsRepository } from "../repository";
import { UserController } from "./index";

const MedSessionsController = {
    medSessions: [] as Array<any>,
    isDirty: false,

    async getMedSessions() {
        const res : any = await MedSessionsRepository.getMedSessions();
        if(res.data.data){
            this.medSessions = res.data.data;
        }
        return res
    },
    async getMedSession(sessionId : any) {
        const postData : any = `tx_wwalprelax_session[sessionId]=${sessionId}`
        const res : any = await MedSessionsRepository.getMedSession(postData);
        //UserController.user.freeSessionsLeft = res.data.data.freeSessionsLeft
        //console.log('freeSessionsLeft updated in MedSessionsController', UserController.user.freeSessionsLeft )
        return res
    },
    async getFile(sessionId : any) {
        const postData : any = `tx_wwalprelax_session[sessionId]=${sessionId}`
        const res : any = await MedSessionsRepository.getFile(postData);

         this.medSessions = this.medSessions.map((item:any) =>{
            if(item.uid == sessionId){
                item.statistic = res.data.data.statistic
            }
            return item
        })
        UserController.user.freeSessionsLeft = res.data.data.freeSessionsLeft
        //console.log('UserController.user in MedSessionController[UserController.user]', UserController.user)
        return res
    }
}
export default MedSessionsController;