
import { defineComponent } from 'vue'
import { UserController } from '@/controller'
import { Button } from '@/ui'
import { Snackbar  } from '@/components/'

//:maxWidth="fixedHeaderMaxWidth" :withLogo="fixedHeaderLogo" :widthLogoutButton="fixedHeaderLogoutButton" :widthBackButton="fixedHeaderBackButton" 

export default defineComponent({
    name: "FixedHeaderTop",
    components: { Button, Snackbar }, 
    props:{
        /* withLogo:{
            type: Boolean, 
            default: false
        }, */
        /* maxWidth:{
            type: Number, 
            default: 0
        }, */
        toView:{
            type: String, 
            default: ""
        },
        /* widthBackButton: {
            type: Boolean,
            default: false
        }, */
        /* widthLogoutButton: {
            type: Boolean,
            default: false
        } */
    },
    data(){
        return{
            icons:{
                arrow: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500" enable-background="new 0 0 500 500" xml:space="preserve"><path fill="#163D64" d="M480.9,258.3H46.6l120.7,92.2c4.5,3.5,5.4,9.9,1.9,14.4c-3.5,4.5-9.9,5.4-14.4,1.9L10.5,256.6c-2.8-1.8-4.7-5-4.7-8.6c0-3.6,1.9-6.8,4.7-8.6l144.3-110.2c4.5-3.5,11-2.6,14.4,1.9c3.5,4.5,2.6,11-1.9,14.4L46.6,237.7h434.3c5.7,0,10.3,4.6,10.3,10.3C491.2,253.7,486.6,258.3,480.9,258.3L480.9,258.3z"/></svg>',
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
                questionmark: '<svg width="45" height="85" viewBox="0 0 45 85" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_35_557)"><path d="M15.1935 57.3823C15.1935 41.4753 35.4194 37.5718 35.4194 22.2503C35.4194 14.4432 30.2903 8.58783 21.4839 8.58783C12.6774 8.58783 9.19355 14.248 7.35484 18.3467L0 14.9311C2.22581 8.78301 8.6129 0 21.6774 0C36.1935 0 45 9.36854 45 21.8599C45 40.9874 24.1935 44.6957 24.1935 57.6751V62.8473H15.0968V57.3823H15.1935ZM19.9355 71.9231C23.5161 71.9231 26.4194 74.8507 26.4194 78.4615C26.4194 82.0723 23.5161 85 19.9355 85C16.3548 85 13.3548 82.0723 13.3548 78.4615C13.3548 74.8507 16.3548 71.9231 19.9355 71.9231Z" fill="#B9C7D1"/></g><defs><clipPath id="clip0_35_557"><rect width="45" height="85" fill="white"/></clipPath></defs></svg>',
                logout: '<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621 444l43-43 176 176-174 174Z"/></svg>',
                close: '<svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="1.22457" y1="31.8427" x2="31.9152" y2="1.15215" stroke="#163D64" stroke-width="3"/><line x1="1.93168" y1="1.15231" x2="32.6223" y2="31.8429" stroke="#163D64" stroke-width="3"/></svg>',
            },

            /* icons:{
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
                account: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 497 540.8" enable-background="new 0 0 497 540.8" xml:space="preserve"><g><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c31.4-30.1,50.9-72.3,50.9-119.2C414,99.2,340,25.1,248.6,25.1S83.2,99.2,83.2,190.5c0,46.9,19.5,89.1,50.9,119.2"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c-29.9,28.6-70.1,46.4-114.8,46.4s-84.9-17.8-114.6-46.4"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M133.8,309.8C73.8,355.4,31.6,435.7,24,529.5"/><path fill="none" stroke="#163D64" stroke-width="35" stroke-miterlimit="10" d="M363.2,309.8c60,45.7,102.2,125.9,109.9,219.7"/></g></svg>', 
                letter: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 263.5" style="enable-background:new 0 0 480 263.5;" xml:space="preserve"><style type="text/css">.st0{display:none;}.st1{display:inline;fill:none;stroke:#163D64;stroke-width:25;stroke-linejoin:round;stroke-miterlimit:10;}.st2{fill:none;stroke:#163D64;stroke-width:25;stroke-linejoin:round;stroke-miterlimit:10;}</style><g class="st0"><path class="st1" d="M32.1,250.9c51.7-40.6,102.4-80.4,153.6-120.6c9.2,6.1,18.3,12.1,27.3,18.1c7,4.6,14,9.2,20.9,14c4.3,3,8,2.9,12.3,0c14.2-9.6,28.5-19,42.7-28.5c1.7-1.1,3.3-2.2,5.5-3.5c50.9,40,101.7,79.9,153.6,120.6C308.9,250.9,171.4,250.9,32.1,250.9z"/><path class="st1" d="M35.2,12.5c137,0,272.7,0,408.4,0c0.4,0.4,0.8,0.8,1.2,1.2c-1.3,0.6-2.6,1.1-3.8,1.9C377.8,57.8,314.7,100,251.5,142.2c-3.8,2.5-7.7,6.7-11.5,6.7c-3.9,0-7.8-4.2-11.5-6.8C165.2,100,102.1,57.7,38.9,15.5C37.9,14.8,37,14,35.2,12.5z"/><path class="st1" d="M173.6,122.1c-51.3,40.3-101.8,80-153.1,120.3c0-74.3,0-147.9,0-222.7C71.5,53.8,122.1,87.6,173.6,122.1z"/><path class="st1" d="M306.4,122.1c51.5-34.4,101.9-68.2,153.2-102.5c0,74.5,0,148.2,0,222.8C408.4,202.2,357.8,162.4,306.4,122.1z"/></g><g><rect x="12.5" y="12.5" class="st2" width="455" height="238.5"/><polyline class="st2" points="467.5,12.5 240,157.5 12.5,12.5 "/><line class="st2" x1="12.5" y1="251" x2="184.5" y2="122.1"/><line class="st2" x1="467.5" y1="251" x2="295.5" y2="122.1"/></g></svg>',
                
                edit: '<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M180 876h44l443-443-44-44-443 443v44Zm614-486L666 262l42-42q17-17 42-17t42 17l44 44q17 17 17 42t-17 42l-42 42Zm-42 42L248 936H120V808l504-504 128 128Zm-107-21-22-22 44 44-22-22Z"/></svg>',
                password: '<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M80 856v-61h800v61H80Zm38-254-40-22 40-68H40v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 22-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Zm324 0-40-24 40-68h-78v-45h78l-40-68 40-22 38 67 38-67 40 22-40 68h78v45h-78l40 68-40 24-38-67-38 67Z"/></svg>',
                watch: '<svg width="34" height="18" viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="outline" d="M33 9C31.5314 10.7009 25.7359 17 17 17C15.0703 17 7.64824 16.6924 1 9C2.46546 7.30219 8.26096 1 17 1C18.9297 1 26.3518 1.30757 33 9Z" stroke="#163D64" stroke-width="1.5" stroke-miterlimit="10"/><path class="point" d="M17.0001 15.2223C19.9456 15.2223 22.3334 12.4365 22.3334 9.00005C22.3334 5.56362 19.9456 2.77783 17.0001 2.77783C14.0546 2.77783 11.6667 5.56362 11.6667 9.00005C11.6667 12.4365 14.0546 15.2223 17.0001 15.2223Z" fill="#163D64"/></svg>',
                downloadBill: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" enable-background="new 0 0 250 250" xml:space="preserve"><g><g><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M169.1,180.5V199c0,2.7-2.2,5-5,5H85.9c-2.7,0-5-2.2-5-5v-18.5"/><polyline fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="162.6,142.9 125.1,180.5 87.4,142.9 "/><line fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="125" y1="86" x2="125" y2="180.5"/></g><g><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M155.4,9.2H41.4c-14.8,0-27,12.1-27,27v177.7c0,14.8,12.1,27,27,27h167.3c14.8,0,27-12.1,27-27V83L155.4,9.2z"/><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M235.6,84.6c-27.1,0-54.2,0-81.3,0c0-25,0-50,0-75"/></g></g></svg>',
                download: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" enable-background="new 0 0 250 250" xml:space="preserve"><g><path fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M210.5,194.2v35.9c0,5.2-4.3,9.7-9.7,9.7H49.2c-5.2,0-9.7-4.3-9.7-9.7v-35.9"/><polyline fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="197.7,121.3 125,194.2 52.1,121.3 "/><line fill="none" stroke="#163D64" stroke-width="12" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="125" y1="11" x2="125" y2="194.2"/></g></svg>',
            }, */

            snackbar: {
                show: false,
                text: '',
                error: false
            },            
        }
    },
    computed:{
        /* getMaxWidthClass(){
            if(this.fixedHeaderMaxWidth == 32){
                return 'max-32'
            } else if(this.fixedHeaderMaxWidth == 48){
                return 'max-48'
            } else {
                return ''
            }
        }, */
        routeName() {
            return this.$route.name
        },        
        fixedHeaderMaxWidth() {
            return this.$route.meta.fixedHeaderMaxWidth ? this.$route.meta.fixedHeaderMaxWidth : 0
        },
        fixedHeaderLogo() {
            return this.$route.meta.fixedHeaderLogo ? true : false
        },
        fixedHeaderLogoutButton() {
            return this.$route.meta.fixedHeaderLogoutButton ? true : false
        },
        fixedHeaderBackButton() {
            return this.$route.meta.fixedHeaderBackButton ? true : false
        },        
        fixedHeaderCloseButton() {
            return this.$route.meta.fixedHeaderCloseButton ? true : false
        },        
    },
    methods:{
        handleGoBack(){
            this.$router.go(-1)
        },
        handleClose(){
            window.close()
        },
        handleGoToFAQs(){
            console.log('router.push')
        },
        async logoutUser(){
            let res = await UserController.logoutUser()
            if(res.data.success){
                this.$router.push("/login")
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },        
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
            }, 5000)
        }        
    }
})
