
import { defineComponent } from 'vue';
import { ContentController } from '@/controller';

export default defineComponent({
    name: "Dialog",
    props:{
        content:{
            type: String, 
            default: ''
        },
        uri: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            displayContent: '',
            icon:{
                close: '<svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="1.22457" y1="31.8427" x2="31.9152" y2="1.15215" stroke="#163D64" stroke-width="3"/><line x1="1.93168" y1="1.15231" x2="32.6223" y2="31.8429" stroke="#163D64" stroke-width="3"/></svg>',
                loader: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform="translate(1 1)"><circle class="st0" cx="18" cy="18" r="18"/><path class="st1" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>'
            }

        }
    },
    created() {
        document.body.classList.add('ww-popup-active')
        if (this.content) {
            this.displayContent = this.content;
        } else if (this.uri) {
            this.fetchContent();
        }
    },
    destroy() {
        document.body.classList.remove('ww-popup-active')
    },
    methods: {
        async fetchContent() {
            this.displayContent =  await ContentController.getContent(this.uri)
        },
        handleClose() {
            this.$emit('onClose')
        }   
    }
})
